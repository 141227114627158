import { Card, Divider, Modal, Typography } from "@mui/material";
import { ConfirmButton } from "../base/cancelConfirmButtons";
import React from "react";
import { ModalWrapper } from "../base/modalComponents";

export const ConfirmModalCommon = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      if (props.modal_open) {
        props.handleOpenCloseModal();
      }
    }
  };
  const modal_body = (
    <ModalWrapper>
      <Card>
        <Typography
          variant="h5"
          align="center"
          style={{ marginLeft: "1vw", marginRight: "1vw", marginTop: "2vh" }}
        >
          {props.interrogative}
        </Typography>
        <Typography
          variant="h6"
          align="center"
          style={{ marginLeft: "1vw", marginRight: "1vw", marginTop: "2vh" }}
        >
          {props.informative}
        </Typography>
        <Divider style={{ marginTop: "1vh" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1vh",
            marginBottom: "1vh",
          }}
        >
          <ConfirmButton
            style={{ marginLeft: "1vw", marginRight: "1vw" }}
            confirm_action={props.button_1_action}
            confirm_text={props.button_1_text}
          ></ConfirmButton>
          <ConfirmButton
            style={{ marginLeft: "1vw", marginRight: "1vw" }}
            confirm_action={props.button_2_action}
            confirm_text={props.button_2_text}
          ></ConfirmButton>
        </div>
      </Card>
    </ModalWrapper>
  );
  //COMPONENT RENDER
  return (
    <Modal
      open={props.modal_open}
      onClose={props.handleOpenCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onKeyDown={handleKeyDown}
    >
      {modal_body}
    </Modal>
  );
};
