import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../Contexts/DataContext";
import useToggle from "../../../hooks/useToggle";
import { createVehicle, editVehicle } from "../../../services/vehicleService";
import { Modal } from "@mui/material";
import vehicle_icon from "../../../images/carIcon.png";
import { v4 as uuidv4 } from "uuid";
import { TextFieldComponent } from "../../base/textFieldComponent";
import { CancelButton, ConfirmButton } from "../../base/cancelConfirmButtons";
import { ProjectIcon } from "../../base/styles";
import { SectionTitle } from "../../base/sectionTitle";
import { SectionSubtitle } from "../../base/sectionSubtitle";
import { ModalWrapper } from "../../base/modalComponents";

export const EditVehicleModal = (props) => {
  const { tripSelected, fetchTripVehicles } = useContext(DataContext);
  const [name, setName] = useState(null);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [confirmButtonText, setConfirmButtonText] = useState(null);
  const [page, setPage] = useState(1);
  const [allCameras, toggleAllCameras] = useToggle(false);
  const [tempCameraArray, setTempCameraArray] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [daysTraveled, setDaysTraveled] = useState(null);
  const isCameraSelected = (e) => tempCameraArray.includes(e);

  const addVehicle = (
    vehicle_name,
    vehicle_make,
    vehicle_model,
    vehicle_cameras,
    vehicle_start_date,
    vehicle_end_date,
    vehicle_days_traveled,
  ) => {
    const response = createVehicle(
      tripSelected,
      vehicle_name,
      vehicle_make,
      vehicle_model,
      vehicle_cameras,
      vehicle_start_date,
      vehicle_end_date,
      vehicle_days_traveled,
    );
    if (response.status === 200) {
      fetchTripVehicles(tripSelected);
    } else {
      console.error(response);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    setConfirmButtonText("Next");
    if (props.cameras && props.cameras.length > 0) {
      setTempCameraArray(props.cameras);
    }
    if (props.startDate) {
      let tempStart = new Date(props.startDate);
      tempStart = tempStart.toISOString().split("T")[0];
      setStartDate(tempStart);
    }
    if (props.endDate) {
      let tempEnd = new Date(props.endDate);
      tempEnd = tempEnd.toISOString().split("T")[0];
      setEndDate(tempEnd);
    }
    if (props.name) {
      setName(props.name);
    }
    if (props.make) {
      setMake(props.make);
    }
    if (props.model) {
      setModel(props.model);
    }
    // eslint-disable-next-line
  }, [props.cameras]);

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  const handleSetMake = (e) => {
    setMake(e.target.value);
  };
  const handleSetModel = (e) => {
    setModel(e.target.value);
  };

  useEffect(() => {
    let check_array = ["Front", "Left", "Right", "360"];
    if (areAllElementsInArray(check_array, tempCameraArray)) {
      toggleAllCameras(true);
    } else {
      toggleAllCameras(false);
    }
    // eslint-disable-next-line
  }, [tempCameraArray]);

  function areAllElementsInArray(testArray, stateArray) {
    for (let i = 0; i < testArray.length; i++) {
      if (!stateArray.includes(testArray[i])) {
        return false;
      }
    }
    return true;
  }

  const handleSetConfirmButtonText = (e) => {
    setConfirmButtonText(e);
  };

  const handleClearForm = () => {
    setName(null);
    setModel(null);
    setMake(null);
  };

  const handleCloseModal = () => {
    handleClearForm();
    setPage(1);
    props.modalHandler();
  };

  const handleSetPage = () => {
    if (page === 1) {
      setPage(2);
    }
    if (page === 2) {
      setPage(3);
    }
    if (page === 3) {
      setPage(4);
      setConfirmButtonText("Submit");
    }
    if (page === 4) {
      if (props.modalType === "add") {
        handleAddVehicle();
      } else {
        handleEditVehicle();
      }
    }
  };

  const handleSetStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleSetEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleSetCameras = (facing) => {
    if (facing === "all") {
      if (allCameras) {
        toggleAllCameras(false);
        setTempCameraArray([]);
      } else {
        toggleAllCameras(true);
        setTempCameraArray(["Front", "Left", "Right", "360"]);
      }
    } else {
      if (!tempCameraArray.includes(facing)) {
        setTempCameraArray([...tempCameraArray, facing]);
      } else {
        setTempCameraArray(tempCameraArray.filter((i) => i !== facing));
      }
    }
  };

  function formatDate(datetime) {
    const date = new Date(datetime);
    const timezoneOffset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);
    const month = adjustedDate.getMonth(); // Months are 0-indexed
    const day = adjustedDate.getDate() + 1;
    const year = adjustedDate.getFullYear() % 100; // Extract last two digits of the year
    return `${month}/${day}/${year}`;
  }

  const handleAddVehicle = () => {
    addVehicle(
      name,
      make,
      model,
      tempCameraArray,
      startDate,
      endDate,
      daysTraveled,
    );
    props.modalHandler();
  };

  const handleEditVehicle = async (props) => {
    const response = await editVehicle(
      props.selectedVehicle,
      name,
      make,
      model,
      tempCameraArray,
      startDate,
      endDate,
      daysTraveled,
    );

    if (response.status === 200) {
      fetchTripVehicles(tripSelected);
    } else {
      console.error(response);
    }
    props.modalHandler();
  };

  return (
    <>
      <>
        <Modal open={props.modalState} key="add">
          <ModalWrapper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "auto",
                marginTop: "1vh",
                marginBottom: "1vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SectionTitle
                title_text={<strong>{`${props.modalType} Vehicle`}</strong>}
              />
              {page === 1 ? (
                <>
                  <SectionSubtitle
                    subtitle_text={<strong>Vehicle Name</strong>}
                  />

                  <TextFieldComponent
                    value={name}
                    setter={handleSetName}
                    placeholder="Car 1"
                  />

                  <SectionSubtitle
                    subtitle_text={<strong>Vehicle Make (optional)</strong>}
                  />

                  <TextFieldComponent
                    setter={handleSetMake}
                    placeholder="Ford"
                    value={make}
                  />

                  <SectionSubtitle
                    subtitle_text={<strong>Vehicle Model (optional)</strong>}
                  />

                  <TextFieldComponent
                    value={model}
                    setter={handleSetModel}
                    placeholder="Explorer"
                  />
                </>
              ) : page === 2 ? (
                <>
                  <SectionTitle
                    title_text={<strong>{`Edit Cameras for ${name}`}</strong>}
                  />

                  <SectionSubtitle subtitle_text={<strong>Facing:</strong>} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "3vw",
                      marginTop: "0vw",
                      marginBottom: "2vh",
                      height: "4vh",
                      width: "45vw",
                      alignItems: "top",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "4vh",
                        marginRight: "2vw",
                      }}
                    >
                      <SectionTitle title_text={"Front"} marginTop={"1vh"} />
                      <input
                        type="checkbox"
                        value="front"
                        checked={isCameraSelected("Front")}
                        onChange={(e) => handleSetCameras("Front")}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "4vh",
                        marginRight: "2vw",
                      }}
                    >
                      <SectionTitle title_text={"Left"} marginTop={"1vh"} />
                      <input
                        type="checkbox"
                        value="left"
                        checked={isCameraSelected("Left")}
                        onChange={(e) => handleSetCameras("Left")}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "4vh",
                        marginRight: "2vw",
                      }}
                    >
                      <SectionTitle title_text={"Right"} marginTop={"1vh"} />
                      <input
                        type="checkbox"
                        value="right"
                        checked={isCameraSelected("Right")}
                        onChange={(e) => handleSetCameras("Right")}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "4vh",
                        marginRight: "2vw",
                      }}
                    >
                      <SectionTitle title_text={"360"} marginTop={"1vh"} />
                      <input
                        type="checkbox"
                        value="360"
                        checked={isCameraSelected("360")}
                        onChange={(e) => handleSetCameras("360")}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "4vh",
                        marginRight: "2vw",
                      }}
                    >
                      <SectionTitle title_text={"All"} marginTop={"1vh"} />
                      <input
                        type="checkbox"
                        value="All"
                        checked={allCameras}
                        onChange={(e) => handleSetCameras("all")}
                      />
                    </div>
                  </div>
                </>
              ) : page === 3 ? (
                <>
                  <SectionTitle
                    title_text={
                      <strong>{`Edit days of travel for ${props.name}`}</strong>
                    }
                  />
                  <SectionSubtitle
                    subtitle_text={<strong>{"Start Date"}</strong>}
                  />
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => handleSetStartDate(e)}
                  />
                  <SectionSubtitle
                    subtitle_text={<strong>{"End Date"}</strong>}
                  />
                  <input
                    type="date"
                    value={endDate}
                    style={{
                      marginBottom: "2vh",
                    }}
                    onChange={(e) => handleSetEndDate(e)}
                  />
                  <SectionSubtitle
                    style={{ marginBottom: "1vh" }}
                    // subtitle_text={
                    //   <strong>{`Total Days of Travel: ${daysTraveled}`}</strong>
                    // }
                  />
                </>
              ) : page === 4 ? (
                <>
                  <SectionTitle
                    title_text={
                      <strong>Review Details and Update Vehicle</strong>
                    }
                  />
                  <ProjectIcon
                    src={vehicle_icon}
                    key={uuidv4()}
                    style={{
                      margin: "auto",
                      marginLeft: "1vw",
                      height: "3vh",
                      width: "auto",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "20vh",
                      width: "85%",
                      alignItems: "left",
                      justifyContent: "left",
                      margin: "auto",
                    }}
                    key={uuidv4()}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                        marginLeft: "0vw",
                      }}
                      key={uuidv4()}
                    >
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Vehicle:</strong>}
                        key={uuidv4()}
                      />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={name}
                        key={uuidv4()}
                      />
                      <span style={{ width: "2vw" }} />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Make:</strong>}
                        key={uuidv4()}
                      />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={make}
                        key={uuidv4()}
                      />

                      <span style={{ width: "2vw" }} />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Model:</strong>}
                        key={uuidv4()}
                      />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={model}
                        key={uuidv4()}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                      }}
                      key={uuidv4()}
                    >
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Total Days:</strong>}
                        key={uuidv4()}
                      />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={daysTraveled}
                        key={uuidv4()}
                      />
                      <span style={{ width: "2vw" }} />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Dates:</strong>}
                        key={uuidv4()}
                      />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={`${formatDate(startDate)}-${formatDate(
                          endDate,
                        )}`}
                        key={uuidv4()}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                      }}
                      key={uuidv4()}
                    >
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Cameras:</strong>}
                        key={uuidv4()}
                      />
                      {tempCameraArray &&
                        tempCameraArray.slice().map((camera) => {
                          return (
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={camera}
                              key={uuidv4()}
                            />
                          );
                        })}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "auto",
                marginTop: "1vh",
                marginBottom: "1vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CancelButton
                cancel_action={() => handleCloseModal()}
                cancel_text={"Cancel"}
              />
              <ConfirmButton
                confirm_action={() => handleSetPage()}
                confirm_text={confirmButtonText}
              />
            </div>
          </ModalWrapper>
        </Modal>
      </>
    </>
  );
};
