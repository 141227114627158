import React, { useContext } from "react";
import { DataContext } from "../../../../Contexts/DataContext";
import { Source, Layer } from "react-map-gl";

const LineColorLayer = (id, sourceLayer, color) => ({
  id: id,
  type: "line",
  "source-layer": sourceLayer,
  layout: { "line-cap": "round" },
  paint: {
    "line-color": color,
    "line-width": 8,
    "line-translate-anchor": "map",
  },
});

const LineOutlineLayer = (id, sourceLayer) => ({
  id: id,
  type: "line",
  "source-layer": sourceLayer,
  layout: { "line-cap": "round" },
  paint: {
    "line-color": "#000",
    "line-width": 8,
    "line-translate-anchor": "map",
  },
});

const LineArrowLayer = (id, sourceLayer) => ({
  id: id,
  type: "line",
  "source-layer": sourceLayer,
  layout: { "line-cap": "round" },
  paint: {
    "line-pattern": "pointicon",
    "line-width": 6,
    "line-translate-anchor": "map",
  },
  filter: ["all", ["<", 13, ["zoom"]]],
});

const PointLayer = (id, sourceLayer, color) => ({
  id: id,
  type: "circle",
  "source-layer": sourceLayer,
  paint: {
    "circle-color": color,
    "circle-stroke-color": "#000000", // Outline color (default black)
    "circle-stroke-width": 0.3, // Outline width (in pixels)
  },
});

const IconLayer = (id, sourceLayer, color) => ({
  id: id,
  type: "symbol",
  "source-layer": sourceLayer,
  layout: {
    "icon-image": [
      "match",
      ["get", "facing"],
      "360",
      "threesixtyCone",
      "viewCone",
    ],
    "icon-size": ["match", ["get", "facing"], "360", 1, 1.5],
    "icon-offset": [
      "match",
      ["get", "facing"],
      "360",
      ["literal", [0, 0]],
      ["literal", [0, -5]],
    ],
    "icon-rotation-alignment": "map",
    "icon-anchor": "center",
    "icon-rotate": ["to-number", ["get", "heading"]],
    "icon-allow-overlap": true,
    "text-allow-overlap": true,
  },
  paint: {
    "icon-opacity": ["match", ["get", "facing"], "360", 0.5, 1.0],
    "icon-halo-width": ["match", ["get", "facing"], "360", 0.0, 5.0],
    "icon-halo-color": "rgba(50,50,50,50)",
    "icon-color": color,
  },
});

export const VectorTileLayerComponent = (props) => {
  const { filterStartDate, filterEndDate, unfilteredViews, currentFeature } =
    useContext(DataContext);

  // Front Line/Point Styles
  const frontLineColorLayer = LineColorLayer(
    "front-line-color",
    "Front",
    "#FFFFFF",
  );
  const frontOutlineLayer = LineOutlineLayer("front-line-outline", "Front");
  const frontArrowLayer = LineArrowLayer("front-arrow-line", "Front");
  const frontPointColorLayer = PointLayer(
    "front-point-color",
    "Front",
    "#FFFFFF",
  );
  const frontIconLayer = IconLayer("front-icon", "Front", "#FFFFFF");

  // Left Line/Point Styles
  const leftLineColorLayer = LineColorLayer(
    "left-line-color",
    "Left",
    "#FF0000",
  );
  const leftOutlineLayer = LineOutlineLayer("left-line-outline", "Left");
  const leftArrowLayer = LineArrowLayer("left-arrow-line", "Left");
  const leftPointColorLayer = PointLayer("left-point-color", "Left", "#FF0000");
  const leftIconLayer = IconLayer("left-icon", "Left", "#FF0000");

  // Right Line/Point Styles
  const rightLineColorLayer = LineColorLayer(
    "right-line-color",
    "Right",
    "#00FF00",
  );
  const rightOutlineLayer = LineOutlineLayer("right-line-outline", "Right");
  const rightArrowLayer = LineArrowLayer("right-arrow-line", "Right");
  const rightPointColorLayer = PointLayer(
    "right-point-color",
    "Right",
    "#00FF00",
  );
  const rightIconLayer = IconLayer("right-icon", "Right", "#00FF00");

  // 360 Line/Point Styles
  const threesixtyLineColorLayer = LineColorLayer(
    "threesixty-line-color",
    "360",
    "#9900FF",
  );
  const threesixtyOutlineLayer = LineOutlineLayer(
    "threesixty-line-outline",
    "360",
  );
  const threesixtyArrowLayer = LineArrowLayer("threesixty-arrow-line", "360");
  const threesixtyPointColorLayer = PointLayer(
    "threesixty-point-color",
    "360",
    "#9900FF",
  );
  const threesixtyIconLayer = IconLayer("threesixty-icon", "360", "#9900FF");

  return (
    <Source id={"devinmarkley.quandary"} type="vector" url={props.url}>
      <Layer
        {...frontOutlineLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...frontLineColorLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...frontArrowLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...frontPointColorLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />

      <Layer
        {...leftOutlineLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...leftLineColorLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...leftArrowLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...leftPointColorLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />

      <Layer
        {...rightOutlineLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...rightLineColorLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...rightArrowLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
        ]}
      />
      <Layer
        {...rightPointColorLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />

      <Layer
        {...threesixtyOutlineLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...threesixtyLineColorLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...threesixtyArrowLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />
      <Layer
        {...threesixtyPointColorLayer}
        filter={[
          "all", // Combine multiple filter expressions using 'all'
          [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
          ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
          ["in", ["get", "facing"], ["literal", unfilteredViews]],
        ]}
      />

      {currentFeature && (
        <>
          <Source id={"devinmarkley.quandary"} type="vector" url={props.url}>
            <Layer
              {...frontIconLayer}
              filter={["==", ["get", "img"], currentFeature.properties.img]}
            />
            <Layer
              {...leftIconLayer}
              filter={["==", ["get", "img"], currentFeature.properties.img]}
            />
            <Layer
              {...rightIconLayer}
              filter={["==", ["get", "img"], currentFeature.properties.img]}
            />
            <Layer
              {...threesixtyIconLayer}
              filter={["==", ["get", "img"], currentFeature.properties.img]}
            />
            <Layer
              id="360_cone_layer_geojson"
              source="devinmarkley.quandary"
              source-layer="360"
              type="symbol"
              layout={{
                "icon-image": "viewCone360",
                "icon-size": 1,
                "icon-rotation-alignment": "map",
                "icon-anchor": "center",
                "icon-offset": [0, -19],
                "icon-rotate": ["to-number", ["get", "heading"]],
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
              }}
              paint={{
                "icon-halo-width": 5,
                "icon-halo-color": "rgba(50,100,50,50)",
                "icon-color": "#B200ED",
              }}
              filter={["==", ["get", "img"], currentFeature.properties.img]}
            />
          </Source>
        </>
      )}
    </Source>
  );
};
