import { Table, TableBody } from "@mui/material";
import { DataContext } from "../../../common/DataContext";
import { AuthContext } from "../../../common/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useToggle from "../../../hooks/useToggle.js";
import {
  DeleteTripModal,
  UPLOADING_TABLE_HEAD,
  ShareProjectModal,
} from "./tripComponents";
import {
  CardMediaStyle,
  ListHead,
  TableCard,
  TableEntryRow,
  TableEntryCell,
  ImagePreviewModal,
} from "../../commonComponents/commonComponents";

export const NewTripTab = (props) => {
  // DATA CONTEXT STATES AND FUNCTIONS //
  const {
    newTrips,
    setNewTrips,
    tripSelected,
    fetchUserTrips,
    orgUsers,
    handleFetchOrgTrips,
  } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  // STATES AND SETTERS //
  const [shareOpen, toggleShareOpen] = useToggle(false);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [imagePreviewOpen, toggleImagePreviewOpen] = useToggle(false);
  const [tempOrgUsers, setTempOrgUsers] = useState([]);

  useEffect(() => {
    if (user && user.role === "admin") {
      handleFetchOrgTrips(props.tab);
    }
    if (user && user.role === "user") {
      fetchUserTrips();
    }
  }, []);

  useEffect(() => {
    setTempOrgUsers(orgUsers);
  }, [orgUsers]);

  const handleShareOpen = () => {
    if (tripSelected !== null) {
      toggleShareOpen();
    }
  };

  // COMPONENT RENDER //
  return (
    <>
      <ImagePreviewModal
        imagePreviewOpen={imagePreviewOpen}
        toggleImagePreviewOpen={toggleImagePreviewOpen}
        url={selectedImageURL}
        alt={"street view image"}
      />

      <ShareProjectModal
        shareOpen={shareOpen}
        tripSelected={tripSelected}
        handleShareOpen={handleShareOpen}
      />

      <TableCard
        style={{
          boxShadow: "1px 1px 6px 2px gray",
          height: "80vh",
          overflowY: "scroll",
        }}
      >
        <CardMediaStyle />
        <Table>
          <ListHead
            headLabel={UPLOADING_TABLE_HEAD}
            tableData={newTrips}
            updateData={setNewTrips}
          />
          <TableBody style={{ width: "100vw" }}>
            {newTrips &&
              newTrips.slice().map((row) => {
                const {
                  name,
                  id,
                  status,
                  description,
                  date_created,
                  country,
                  city,
                  // total_days,
                  // total_teams,
                  vehicles,
                  drive_team,
                  // day_uploading,
                } = row;
                return (
                  <TableEntryRow
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(145, 165, 172, 0.5)",
                        cursor: "pointer",
                      },
                    }}
                    align="center"
                    key={id}
                    tabIndex={-1}
                    onClick={() =>
                      props.handleClickSelectTrip(
                        id,
                        name,
                        description,
                        city,
                        country,
                        vehicles,
                        drive_team,
                        status,
                      )
                    }
                    selected={tripSelected === id}
                    onDoubleClick={() => props.view_all_project_sequences(id)}
                  >
                    <TableEntryCell entry={<strong>{name}</strong>} />
                    <TableEntryCell entry={id} />
                    <TableEntryCell entry={country} />
                    <TableEntryCell entry={city} />
                    <TableEntryCell entry={vehicles} />
                    <TableEntryCell entry={"coming soon"} />
                    <TableEntryCell entry={date_created} />
                  </TableEntryRow>
                );
              })}
          </TableBody>
        </Table>
      </TableCard>
    </>
  );
};
