import { poster } from "../calls";
import { Navigate } from "react-router-dom";
import React from "react";

export async function listS3Objects(path, media_type) {
  try {
    return await poster(
      {
        path,
        media_type,
      },
      "upload/ListObjectsDigitalOcean",
    );
  } catch (error) {
    console.error(error);
  }
}

export async function checkS3Connection(
  bucket,
  endpoint,
  token,
  secret,
  region,
) {
  try {
    const response = await poster(
      {
        bucket: bucket,
        endpoint: endpoint,
        access_key: token,
        secret: secret,
        region: region,
      },
      "upload/checkCloudConnection",
    );
    return response.valid;
  } catch (error) {
    console.error(error);
  }
}

export async function processCloudFiles(
  path,
  files,
  trip_id,
  processSubfolders = false,
  selectedFolder = null,
  mediaType = null,
  selectedDay,
  selectedCameraFacing,
  selectedCamera,
  selectedVehicle,
) {
  try {
    return await poster(
      {
        trip_id: trip_id,
        day_id: selectedDay,
        camera_facing: selectedCameraFacing,
        camera_id: selectedCamera,
        vehicle_id: selectedVehicle,
        path: path,
        files: files,
        processSubfolders: processSubfolders,
        selectedFolder: selectedFolder,
        mediaType: mediaType,
      },
      "process/trip_sequences",
    );
  } catch (error) {
    console.error(error);
  }
}
