import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../Contexts/DataContext";
import { createVehicle } from "../../../services/vehicleService";
import { Modal } from "@mui/material";
import { TextFieldComponent } from "../../base/textFieldComponent";
import { CancelButton, ConfirmButton } from "../../base/cancelConfirmButtons";
import { SectionTitle } from "../../base/sectionTitle";
import { SectionSubtitle } from "../../base/sectionSubtitle";
import { ModalWrapper } from "../../base/modalComponents";
import { GenericModal } from "../../composite/genericModal";

export const NewVehicleModal = (props) => {
  const { tripSelected } = useContext(DataContext);
  const [name, setName] = useState(null);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
  }, [props.cameras]);

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  const handleSetMake = (e) => {
    setMake(e.target.value);
  };
  const handleSetModel = (e) => {
    setModel(e.target.value);
  };

  const handleClearForm = () => {
    setName(null);
    setModel(null);
    setMake(null);
    setHighlight(false);
  };
  const handleCloseModal = () => {
    handleClearForm();
    props.modalHandler();
  };

  const handleCreateVehicle = async () => {
    if (name) {
      createVehicle(tripSelected, name, make, model);
      handleClearForm();
      props.modalHandler();
    } else {
      setHighlight(true);
    }
  };
  return (
    <GenericModal
      isOpen={props.modalState}
      onClose={() => handleCloseModal()}
      title="Add Vehicle"
      subtitle="Adding a Vehicle currently has no effect on the upload process"
      confirmText="Add"
      onConfirm={() => handleCreateVehicle()}
    >
      <SectionSubtitle
        subtitle_text={<strong>Vehicle Name (required)</strong>}
      />

      <TextFieldComponent
        value={name}
        setter={handleSetName}
        placeholder="Car 1"
        isMissing={highlight}
      />

      <SectionSubtitle
        subtitle_text={<strong>Vehicle Make (optional)</strong>}
      />

      <TextFieldComponent
        value={make}
        setter={handleSetMake}
        placeholder="Ford"
      />

      <SectionSubtitle
        subtitle_text={<strong>Vehicle Model (optional)</strong>}
      />

      <TextFieldComponent
        value={model}
        setter={handleSetModel}
        placeholder="Exploder"
      />
    </GenericModal>
  );
};
