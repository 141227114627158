import React from "react";
import { GenericModal } from "../../composite/genericModal";

export const DeleteTripModal = (props) => {
  return (
    <GenericModal
      isOpen={props.deleteOpen}
      onClose={props.handleDeleteOpen}
      title={`Delete Trip: ${props.tripName}`}
      subtitle="Are you sure you want to delete the following trip?"
      confirmText="Delete"
      onConfirm={props.handle_delete_trip}
    />
  );
};
