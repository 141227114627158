import styled from "styled-components";

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
`;

export const Image = styled.img`
  right: 0;
  color: #fff;
  width: 100%;
  height: 100%;
`;

export const Fullscreen = styled.button`
  top: 1%;
  right: 1%;
  font-size: 20px;
  position: absolute;
  background-color: transparent;
  color: transparent;
  border: none;
  margin-right: 0;
  margin-top: 0;
  border-radius: 50%;
  max-width: 0.1vw;
  max-height: 0.1vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: -5;
`;
