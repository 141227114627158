import { Input } from "../../base/styles";
import React from "react";
import { GenericModal } from "../../composite/genericModal";

export const AddUserModal = (props) => (
  <GenericModal
    isOpen={props.addOpen}
    onClose={props.handleAddOpen}
    title="Invite a new user"
    subtitle="An invitation to join Viewer under your organization will be sent to the email address entered below"
    confirmText="Send"
    onConfirm={props.do_invite_user}
  >
    <Input
      type="text"
      name="name"
      placeholder="example@gmail.com"
      onChange={(e) => {
        props.handleSetInviteEmail(e.target.value);
      }}
    />
  </GenericModal>
);
