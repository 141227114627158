//IMPORTS
import styled from "styled-components";
import React from "react";
import loadingArrow from "../../images/25.svg";

//PRELOADER ICON ROTATE TRANSFORM

//PRELOADER ICON SETUP
export const Preloader = styled.img.attrs({
  src: loadingArrow,
})`
  background: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

//COMPONENT DECLARATION SETUP & RENDER - IMAGE PRELOADER ICON (SPINNING CIRCLES WHILE IMAGE LOADING)
export const PreloaderIcon = () => {
  return (
    <Preloader
      style={{
        position: "relative",
        left: "7px",
        top: "11px",
        width: "25%",
      }}
    />
  );
};
