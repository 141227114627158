import styled from "styled-components";
import left_camera from "../../../../images/left_red.png";
import right_camera from "../../../../images/right_green.png";
import front_camera from "../../../../images/front_white.png";
import threesixty_camera from "../../../../images/threesixty_purple.png";

export const DirectionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 25vw;
  border-radius: 6px;
  height: 4vh;
  box-shadow: 1px 1px 4px 1px black;
  background: rgba(66, 71, 75, 0.6);
  margin-bottom: 1vh;
`;

export const LeftCamera = styled.div`
  background-image: url(${left_camera});

  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 22px 22px;
  width: 15%;
  height: 100%;
  margin: auto;
  &:hover {
    cursor: pointer;
    background-color: #91a5ac;
    border-radius: 45px;
  }
`;

export const ThreeSixtyCamera = styled.div`
  background-image: url(${threesixty_camera});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 22px 22px;
  width: 15%;
  height: 100%;
  margin: auto;
  &:hover {
    cursor: pointer;
    background-color: #91a5ac;
    border-radius: 45px;
  }
`;

export const FrontCamera = styled.div`
  background-image: url(${front_camera});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 22px 22px;
  width: 15%;
  height: 100%;
  margin: auto;
  &:hover {
    cursor: pointer;
    background-color: #91a5ac;
    border-radius: 45px;
  }
`;

export const RightCamera = styled.div`
  background-image: url(${right_camera});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 22px 22px;
  width: 15%;
  height: 100%;
  margin: auto;
`;
