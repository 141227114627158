import { poster } from "../calls";

export async function editVehicle(
  vehicle_id,
  vehicle_name,
  vehicle_make,
  vehicle_model,
  vehicle_cameras,
  vehicle_start_date,
  vehicle_end_date,
  vehicle_days_traveled,
) {
  try {
    return await poster(
      {
        vehicle_id: vehicle_id,
        vehicle_name: vehicle_name,
        vehicle_make: vehicle_make,
        vehicle_model: vehicle_model,
        vehicle_cameras: vehicle_cameras,
        vehicle_start_date: vehicle_start_date,
        vehicle_end_date: vehicle_end_date,
        vehicle_days_traveled: vehicle_days_traveled,
      },
      "project/editVehicle",
    );
  } catch (error) {
    console.error(error);
  }
}

export async function createVehicle(
  tripSelected,
  vehicle_name,
  vehicle_make,
  vehicle_model,
  vehicle_cameras,
  vehicle_start_date,
  vehicle_end_date,
  vehicle_days_traveled,
) {
  try {
    return await poster(
      {
        trip_id: tripSelected,
        vehicle_name: vehicle_name,
        vehicle_make: vehicle_make,
        vehicle_model: vehicle_model,
        vehicle_cameras: vehicle_cameras,
        vehicle_start_date: vehicle_start_date,
        vehicle_end_date: vehicle_end_date,
        vehicle_days_traveled: vehicle_days_traveled,
      },
      "project/addVehicle",
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getVehiclesByTripID(trip_id) {
  try {
    return await poster({ trip_id: trip_id }, "project/fetch_trip_vehicles");
  } catch (error) {
    console.error(error);
  }
}

export async function deleteVehicle(vehicle_id) {
  try {
    return await poster({ vehicle_id: vehicle_id }, "project/deleteVehicle");
  } catch (error) {
    console.error(error);
  }
}
