// IMPORTS
import { useCallback, useContext } from "react";
import React from "react";
import { useDropzone } from "react-dropzone";
import { Map } from "../MapViewer";
import togeojson from "@mapbox/togeojson";
import bbox from "@turf/bbox";
import { DataContext } from "../../../../Contexts/DataContext";
// COMPONENT DECLARATION & SETUP - WIP -  FILE DROP ZONE FOR MAP VIEWER
export function DropzoneMap() {
  const { mapRef, setGPXfile } = useContext(DataContext);

  const gpx_file = /^.*(\.gpx)$/;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        // For some reason, file type was not appearing on some machines,
        // prioritize file type, then fallback to matching file name (not ideal)
        if (
          (file.type && file.type === "application/gpx+xml") ||
          (!file.type && file.name.match(gpx_file))
        ) {
          // We need to parse the file into a DOM XML
          // then pass that DOM obj into the mapbox .gpx() function,
          // as mapbox expects sources to be either tiles (raster/vector) or GeoJSON
          const parser = new DOMParser();
          const xmlDom = parser.parseFromString(
            reader.result,
            "application/xml",
          );
          const geoJSON = togeojson.gpx(xmlDom);
          setGPXfile(geoJSON);
          const bounds = bbox(geoJSON);
          const lng = (bounds[2] + bounds[0]) / 2;
          const lat = (bounds[3] + bounds[1]) / 2;
          const map = mapRef.current.getMap();

          if (!map.getSource("file_data")) {
            map.addSource("file_data", {
              type: "geojson",
              data: geoJSON,
            });
            map.addLayer({
              id: "file_data_layer",
              source: "file_data",
              type: "line",
              paint: {
                "line-color": "blue",
                "line-opacity": 0.75,
                "line-width": 2.5,
              },
            });
          } else {
            map.removeLayer("file_data_layer");
            map.removeSource("file_data");
            map.addSource("file_data", {
              type: "geojson",
              data: geoJSON,
            });
            map.addLayer({
              id: "file_data_layer",
              source: "file_data",
              type: "line",
              paint: {
                "line-color": "blue",
                "line-opacity": 0.75,
                "line-width": 2.5,
              },
            });
          }
          mapRef.current.getMap().easeTo({
            center: [lng, lat],
            zoom: 12,
            duration: 300,
          });
        }
      };
      reader.readAsText(file);
    });
    // eslint-disable-next-line
  }, []);

  const { getRootProps } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <div {...getRootProps()} className="map-viewer">
      <Map></Map>
    </div>
  );
}
