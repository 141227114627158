import React, { useContext } from "react";
import { DataContext } from "../../../../Contexts/DataContext";
import {
  DirectionBar,
  LeftCamera,
  ThreeSixtyCamera,
  FrontCamera,
  RightCamera,
} from "./styles";

export const DirectionPicker = () => {
  const { handleSetSelectedView, selectedView } = useContext(DataContext);
  return (
    <DirectionBar>
      <LeftCamera
        style={
          selectedView === "Left"
            ? { backgroundColor: "#FF0000", borderRadius: "45px" }
            : { backgroundColor: "unset", borderRadius: "0px" }
        }
        name="view_direction"
        value="LeftCamera"
        data-tip
        data-for="leftCamera"
        onClick={() => handleSetSelectedView("Left")}
      />
      <FrontCamera
        style={
          selectedView === "Front"
            ? { backgroundColor: "#FFFFFF", borderRadius: "45px" }
            : { backgroundColor: "unset", borderRadius: "0px" }
        }
        value="Front"
        data-tip
        data-for="frontCamera"
        onClick={() => handleSetSelectedView("Front")}
      />
      <RightCamera
        style={
          selectedView === "Right"
            ? { backgroundColor: "#00FF00", borderRadius: "45px" }
            : { backgroundColor: "unset", borderRadius: "0px" }
        }
        data-tip
        value="RightCamera"
        data-for="rightCamera"
        onClick={() => handleSetSelectedView("Right")}
      />
      <ThreeSixtyCamera
        style={
          selectedView === "360"
            ? { backgroundColor: "#e920e9", borderRadius: "45px" }
            : { backgroundColor: "unset", borderRadius: "0px" }
        }
        value="360°"
        data-tip
        data-for="360Camera"
        onClick={() => handleSetSelectedView("360")}
      />
    </DirectionBar>
  );
};
