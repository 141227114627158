import React from "react";
import { ConfirmButton } from "../../base/cancelConfirmButtons";
import { DropdownMenu } from "../../composite/dropdownMenu";
import { SectionTitle } from "../../base/sectionTitle";

export const SelectionRow = (props) => {
  const renderInputs = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // alignItems: "left",
        }}
      >
        <SectionTitle title_text={props.title} marginTop={"1vh"} />
        {props.inputs &&
          props.inputs.map((input) => (
            <>
              <SectionTitle title_text={input.title} marginTop={"1vh"} />

              {input.type === "radio" ? (
                <input
                  type="radio"
                  value={input.value}
                  checked={input.value === props.valueState}
                  onChange={(e) => props.stateHandler(e)}
                  disabled={input.isDisabled}
                />
              ) : input.type === "file" ? (
                <>
                  <input
                    style={{ width: "auto", marginTop: "1vh" }}
                    type="file"
                    multiple
                    accept=".jpg, .JPG, .jpeg, .JPEG"
                    onChange={
                      props.handleSetLocalImages && props.handleSetLocalImages
                        ? (e) => props.handleSetLocalImages(e)
                        : null
                    }
                  />
                </>
              ) : input.type === "text" ? null : input.type === "button" ? (
                <ConfirmButton
                  confirm_text={input.button_text}
                  confirm_action={
                    input.stateHandler ? input.stateHandler : props.stateHandler
                  }
                />
              ) : input.type === "dropdown" ? (
                <>
                  <DropdownMenu
                    processSubfolders={props.processSubfolders}
                    selectedFolder={props.selectedFolder}
                    menu_title={input.menu_title}
                    options={input.options}
                    onSelect={props.stateHandler}
                  />
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  return <>{renderInputs()}</>;
};
