import React, { useContext, useEffect, useState } from "react";
import { InteractionContext } from "../../../../Contexts/InteractionContext";
import { DataContext } from "../../../../Contexts/DataContext";
import PrismaZoom from "react-prismazoom";
import { ImageWrapper, Image, Fullscreen } from "./styles";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import image1 from "../../../../images/404.jpg";

//IMAGE VIEWER COMPONENT ON MAP PAGE
export default function ImageViewer() {
  const { imageRef, isFullscreen, setisFullscreen, FullArrow } =
    useContext(InteractionContext);

  const {
    selectedPicture,
    zoom,
    setZoom,
    prismaZoom,
    imageBrightness,
    handleToggleMapMenus,
    isMapSwap,
  } = useContext(DataContext);

  //COMPONENT STATES
  const [scale, setScale] = useState(1);
  const [initialX, setInitialX] = useState(0);
  const [initialY, setInitialY] = useState(0);
  const [imageViewer, setImageViewer] = useState();
  const pSRef = React.createRef();
  //USE EFFECTS

  // CONTROLS THE SIZE OF THE IMAGE VIEWER COMPONENT BASED ON SCREEN SIZE & COMPONENT SWAP
  useEffect(() => {
    if (isFullscreen) {
      imageRef.current.style.height = "100vh";
      imageRef.current.style.width = "100vw";
    } else if (!isFullscreen) {
      imageRef.current.style.height = "";
      imageRef.current.style.width = "";
      FullArrow.current.style.transform = "";
    }
  }, [isFullscreen, FullArrow, imageRef]);

  //SETS THE SCALE OF THE CURRENT IMAGE
  useEffect(() => {
    if (selectedPicture) {
      setScale(scale);
      setInitialX(initialX);
      setInitialY(initialY);
      setImageViewer(
        <Image
          src={selectedPicture.img_url}
          style={{ filter: `brightness(${imageBrightness}%)` }}
        />,
      );
    }
  }, [selectedPicture, imageBrightness]);

  const onZoomChange = () => {
    setZoom(zoom);
  };

  useEffect(() => {
    if (selectedPicture.facing === "360") {
      pSRef.current.setPanorama(selectedPicture.img_url, {
        transition: false,
        showLoader: false,
        zoom: 0,
      });
    }
  }, [selectedPicture, pSRef]);

  return (
    <>
      <ImageWrapper onClick={() => handleToggleMapMenus()} ref={imageRef}>
        {selectedPicture.facing === "360" ? (
          <div style={{ filter: `brightness(${imageBrightness}%)` }}>
            <ReactPhotoSphereViewer
              ref={pSRef}
              width={isMapSwap && isMapSwap === true ? "100vw" : "20vw"}
              height={isMapSwap && isMapSwap === true ? "100vh" : "25vh"}
              container={"container-360"}
              src={image1}
              navbar={false}
            />
          </div>
        ) : (
          <>
            <div id="brightness" style={{ height: "100vh" }}>
              <PrismaZoom
                onZoomChange={onZoomChange}
                maxZoom={8}
                ref={prismaZoom}
                style={{
                  width: isMapSwap && isMapSwap === true ? "100vw" : "20vw",
                  height: isMapSwap && isMapSwap === true ? "100vh" : "25vh",
                }}
                children={0}
              >
                {imageViewer}
              </PrismaZoom>
            </div>
          </>
        )}
        <Fullscreen
          ref={FullArrow}
          onClick={() => {
            setisFullscreen(!isFullscreen);
          }}
        ></Fullscreen>
      </ImageWrapper>
    </>
  );
}
