import { poster } from "../calls";
export async function fetchTripByType(type) {
  try {
    const response = await poster(
      { upload_complete: type },
      "project/fetch_org_trips",
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function createTrip(
  visibility,
  name,
  desc = null,
  country = null,
  city = null,
  vehicles = null,
  driveTeam,
  tripStartDate,
  tripEndDate,
  backupFolder,
) {
  try {
    let outpack = {
      visibility: visibility,
      name: name,
      desc: desc,
      country: country,
      city: city,
      vehicles: vehicles,
      driveTeam: driveTeam,
      startDate: tripStartDate,
      endDate: tripEndDate,
      backupFolder: backupFolder,
    };
    const response = await poster(outpack, "project/create_trip");
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
  }
}
