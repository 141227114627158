import { Table } from "@mui/material";
import { DataContext } from "../../../common/DataContext";
import { AuthContext } from "../../../common/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import useToggle from "../../../hooks/useToggle.js";
import { TRIPS_TABLE_HEAD, ShareProjectModal } from "./tripComponents";
import {
  CardMediaStyle,
  ListHead,
  TableCard,
  TableEntryRow,
  TableEntryCell,
  ImagePreviewModal,
} from "../../commonComponents/commonComponents";

export const ReadyTripTab = (props) => {
  // DATA CONTEXT STATES AND FUNCTIONS //
  const { handleFetchOrgTrips, readyTrips, tripSelected } =
    useContext(DataContext);
  const { user } = useContext(AuthContext);
  // STATES AND SETTERS //
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [imagePreviewOpen, toggleImagePreviewOpen] = useToggle(false);
  const [shareOpen, toggleShareOpen] = useToggle(false);

  // FETCH ALL ORG PROJECTS ON COMPONENT MOUNT //
  useEffect(() => {
    if (user && user.role === "admin") {
      handleFetchOrgTrips(props.tab);
    }
  }, []);

  // TOGGLE SHARE PROJECT MODAL OPEN IF PROJECT SELECTED //
  const handleShareOpen = () => {
    if (tripSelected !== null) {
      toggleShareOpen();
    }
  };
  // COMPONENT RENDER //
  return (
    <>
      <ImagePreviewModal
        imagePreviewOpen={imagePreviewOpen}
        toggleImagePreviewOpen={toggleImagePreviewOpen}
        url={selectedImageURL}
        alt={"street view image"}
      />

      <ShareProjectModal
        shareOpen={shareOpen}
        tripSelected={tripSelected}
        handleShareOpen={handleShareOpen}
      />

      <TableCard
        style={{
          boxShadow: "1px 1px 6px 2px gray",
          height: "80vh",
          overflowY: "scroll",
        }}
      >
        <CardMediaStyle />
        <Table>
          <ListHead
            headLabel={TRIPS_TABLE_HEAD}
            // tableData={newTrips}
            // updateData={setNewTrips}
          />
          {readyTrips &&
            readyTrips.slice().map((row) => {
              const {
                name,
                id,
                status,
                description,
                date_created,
                country,
                city,
                // total_days,
                total_teams,
                vehicles,
                // day_uploading,
              } = row;
              return (
                <TableEntryRow
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(145, 165, 172, 0.5)",
                      cursor: "pointer",
                    },
                  }}
                  align="center"
                  // hover
                  key={id}
                  tabIndex={-1}
                  onClick={() =>
                    props.handleClickSelectTrip(
                      id,
                      name,
                      description,
                      city,
                      country,
                      status,
                    )
                  }
                  selected={tripSelected === id}
                  onDoubleClick={() => props.view_all_project_sequences(id)}
                >
                  <TableEntryCell entry={<strong>{name}</strong>} />
                  <TableEntryCell entry={id} />
                  <TableEntryCell entry={country} />
                  <TableEntryCell entry={city} />
                  <TableEntryCell entry={vehicles} />
                  <TableEntryCell entry={total_teams} />
                  <TableEntryCell entry={date_created} />
                </TableEntryRow>
              );
            })}
        </Table>
      </TableCard>
    </>
  );
};
