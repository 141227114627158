import { createTheme } from "@mui/material/styles";
import { useQueryString } from "../../hooks/useQueryString";
import React, { createContext, useState, useRef } from "react";

export const InteractionContext = createContext({});
export const InteractionProvider = (props) => {
  const { children } = props;
  const [selectedPictureId, setSelectedPictureId] = useQueryString("pic", 0);
  const [currentZoom, setCurrentZoom] = useState(10);
  const [z, setZ] = useQueryString("z", 0);
  const [isFullscreen, setisFullscreen] = useState(false);
  const imageRef = useRef(null);
  const FullArrow = useRef(null);
  const name = "Viewer";
  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
      secondary: {
        main: "#6a6c7c",
      },
    },
  });

  const value = {
    selectedPictureId,
    setSelectedPictureId,
    z,
    setZ,
    name,
    theme,
    imageRef,
    isFullscreen,
    FullArrow,
    setisFullscreen,
    currentZoom,
    setCurrentZoom,
  };

  return (
    <InteractionContext.Provider value={value}>
      {children}
    </InteractionContext.Provider>
  );
};
