import React from "react";
import { styled } from "@mui/material/styles";
import { GenericModal } from "../../composite/genericModal";

export const ModifyUserModal = (props) => {
  return (
    <GenericModal
      isOpen={props.modifyOpen}
      onClose={props.handleModifyOpen}
      title={"Edit User"}
      subtitle={"Edit the role of the selected user within your organization"}
      confirmText={"Confirm"}
      onConfirm={props.do_modify_user}
    >
      <RoleDiv>
        <input
          type="radio"
          value="Admin"
          name="role"
          onChange={() => props.handleRoleSelected("admin")}
          checked={props.roleSelected === "admin"}
        />
        <span style={{ width: ".5vw" }} />
        <strong>Admin</strong>
        <span style={{ width: "5vw" }} />
        <input
          type="radio"
          value="User"
          name="role"
          onChange={() => props.handleRoleSelected("user")}
          checked={props.roleSelected === "user"}
        />
        <span style={{ width: ".5vw" }} />
        <strong>User</strong>
      </RoleDiv>
    </GenericModal>
  );
};

export const RoleDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}));
