import React, { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "./table";
import "./style.css";

// Define the CustomTable component that wraps the open-source Table component
const CustomTable = ({ data = [], columns, headers, handleOnClick }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const handleRowClick = (row, index) => {
    setSelectedRowIndex(index);
    handleOnClick(row);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const tableElement = document.querySelector(".custom-table");
      if (tableElement && !tableElement.contains(event.target)) {
        setSelectedRowIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <Table className="custom-table">
        <TableHeader className="custom-table-header">
          <TableRow>
            {headers.map((col) => (
              <TableHead key={col} className="custom-table-cell">
                {col}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={`custom-table-row ${
                selectedRowIndex === rowIndex ? "bg-blue-100" : ""
              }`}
              onClick={() => handleRowClick(row, rowIndex)}
            >
              {columns.map((col, colIndex) => (
                <TableCell key={colIndex} className="custom-table-cell">
                  {row[col]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default CustomTable;
