import { Typography } from "@mui/material";
import React from "react";

export const SectionTitle = (props) => {
  return (
    <Typography
      variant="h5"
      align="center"
      style={{
        paddingLeft: props.paddingLeft ? props.paddingLeft : "1vw",
        paddingRight: props.paddingRight ? props.paddingRight : "1vw",
        marginBottom: props.marginBottom ? props.marginBottom : "0vh",
        marginTop: props.marginTop ? props.marginTop : "0vh",
      }}
      sx={{ mt: 6 }}
    >
      {props.title_text}
    </Typography>
  );
};
