//BUTTON DIV COMPONENT
import { StyledButton } from "../base/styledButton";
import React from "react";

export const ButtonDivComponent = ({ buttons = [] }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      {buttons.map(({ action, text }, index) => (
        <StyledButton key={index} button_action={action} button_text={text} />
      ))}
    </div>
  );
};
