import styled from "styled-components";
import { SectionTitle } from "./sectionTitle";
import { CloseButton } from "./closeButton";
import React from "react";

export const ModalWrapper = styled("div")(() => ({
  position: "fixed",
  top: "50%",
  left: "55%",
  backgroundColor: "white",
  borderRadius: "6px",
  maxWidth: "55%",
  minWidth: "40%",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  height: "auto",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
  gap: "1rem",
}));

export const ModalTitle = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <span />
      <SectionTitle title_text={props.title} />
      <CloseButton close_action={props.closeButtonAction} />
    </div>
  );
};
