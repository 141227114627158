import React, { useEffect, useState } from "react";
import { createCamera, editCamera } from "../../../services/cameraService";
import { TextFieldComponent } from "../../base/textFieldComponent";
import { SectionSubtitle } from "../../base/sectionSubtitle";
import { GenericModal } from "../../composite/genericModal";

export const EditCameraModal = (props) => {
  const [newCameraMake, setNewCameraMake] = useState();
  const [newCameraModel, setNewCameraModel] = useState();

  useEffect(() => {
    setNewCameraMake(props.selectedCameraMake);
    setNewCameraModel(props.selectedCameraModel);
  }, [props]);

  const handleSetNewCameraMake = (e) => {
    setNewCameraMake(e.target.value);
  };

  const handleSetNewCameraModel = (e) => {
    setNewCameraModel(e.target.value);
  };

  const handleEditCamera = async () => {
    const response = await editCamera(
      props.selectedCamera,
      newCameraMake,
      newCameraModel,
    );
    if (response.status === 200) {
      alert("Camera Updated");
    } else {
      console.error(response);
    }
    props.modalHandler();
  };

  return (
    <GenericModal
      isOpen={props.modalState}
      onClose={props.modalHandler()}
      title={`Edit ${props.function} Camera`}
      subtitle="An invitation to join Viewer under your organization will be sent to the email address entered below"
      confirmText="Submit"
      onConfirm={(e) => handleEditCamera(e)}
    >
      <SectionSubtitle
        subtitle_text={<strong>Camera Make (optional)</strong>}
      />

      <TextFieldComponent
        setter={handleSetNewCameraMake}
        placeholder="GoPro"
        value={newCameraMake}
      />

      <SectionSubtitle
        subtitle_text={<strong>Camera Model (optional)</strong>}
      />

      <TextFieldComponent
        value={newCameraModel}
        setter={handleSetNewCameraModel}
        placeholder="Hero 10"
      />
    </GenericModal>
  );
};
