// GENERAL CLOSE MODAL BUTTON
import close_icon from "../../images/close_icon.png";
import React from "react";

export const CloseButton = (props) => {
  return (
    <img
      src={close_icon}
      style={{
        position: "relative",
        width: "1rem",
        height: "1rem",
      }}
      alt={"close_button"}
      onClick={props.close_action}
    />
  );
};
