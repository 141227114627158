// TEXT FIELD //
import { Input } from "./styles";
import React from "react";

export const TextFieldComponent = (props) => {
  return (
    <Input
      style={{
        marginLeft: "2.5vw",
        borderColor: props.isMissing ? "red" : undefined,
        borderWidth: props.isMissing ? "2px" : undefined,
        borderStyle: props.isMissing ? "solid" : undefined,
      }}
      type="text"
      placeholder={props.placeholder}
      name={props.name}
      value={props.value}
      onChange={(e) => {
        props.setter(e);
      }}
    />
  );
};
