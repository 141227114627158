import React, { useContext, useState } from "react";
import { DropzoneMap } from "../../components/features/map/Dropzone";
import ImageViewer from "../../components/features/map/ImageViewer";
import { ImagePlaceholder } from "../../components/composite/ImagePlaceholder";
import "react-tabs/style/react-tabs.css";
import { DataContext } from "../../Contexts/DataContext";
import { AuthContext } from "../../Contexts/AuthContext";
import useToggle from "../../hooks/useToggle";
import {
  FilterWidget,
  LayerWidget,
  ToolsWidget,
  ToolsModal,
  SearchModal,
  AddImagesModals,
  FilterModal,
  Minimap,
  ToolBar,
  SearchWidget,
} from "./viewerComponents";
import { useNavigate } from "react-router-dom";
import { ExportGpxModal } from "../../components/composite/exportGpxModal";
import { StyledButton } from "../../components/base/styledButton";

//COMPONENT DECLARATION & SETUP
export const Viewer = () => {
  const [modalPage, setModalPage] = useState(1);
  const [exportSelection, setExportSelection] = useState(null);
  const [exportView, setExportView] = useState(null);
  const [exportModalOpen, toggleExportModalOpen] = useToggle(false);
  const [tempImageryType, setTempImageryType] = useState(null);
  const [cameraFacing, setCameraFacing] = useState(null);
  const [rotationReversed, setRotationReversed] = useState(false);
  const [publicMap, togglePublicMap] = useState(false);
  const [searchOpen, toggleSearchOpen] = useToggle(false);

  const {
    setDrawMode,
    mapRef,
    lastZoomedPicture,
    toggleLayerMenuOpen,
    filterMenuOpen,
    toggleFilterMenuOpen,
    selectedPicture,
    isMapSwap,
    handleIsMapSwap,
    fly_to_selected,
    filterParameters,
    setFilterParameters,
    toolMenuOpen,
    toggleToolMenuOpen,
    setImageBrightness,
    followInJosm,
    toggleFollowInJosm,
    rotateOffset,
    setRotateOffset,
    tripSelected,
    sequenceSelected,
    exportGPX,
    setGPXfile,
    selectedView,
    josmPictureCommand,
    setLastZoomedPicture,
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);

  const handleSetImageryType = (e) => {
    setTempImageryType(e.target.value);
  };

  const handleSetCameraFacing = (e) => {
    setCameraFacing(e.target.value);
  };

  const handleToggleFilterMenu = (e) => {
    toggleLayerMenuOpen(false);
    toggleToolMenuOpen(false);
    toggleSearchOpen(false);
    if (e) {
      toggleFilterMenuOpen(e);
    } else {
      toggleFilterMenuOpen();
    }
  };

  const handleToggleToolsMenu = (e) => {
    toggleLayerMenuOpen(false);
    toggleFilterMenuOpen(false);
    toggleSearchOpen(false);
    if (e) {
      toggleToolMenuOpen(e);
    } else {
      toggleToolMenuOpen();
    }
  };

  const handleSetImageBrightness = (e) => {
    setImageBrightness(e.target.value);
  };

  const handleFollowInJosm = () => {
    toggleFollowInJosm();
  };

  const handleFlyTo = () => {
    fly_to_selected();
  };

  const onClickLoadAndZoom = (e) => {
    e.preventDefault();
    try {
      josmPictureCommand("/zoom", selectedPicture);
    } catch (e) {
      console.log(e);
    }
  };
  try {
    if (
      followInJosm &&
      selectedPicture !== null &&
      lastZoomedPicture !== selectedPicture
    ) {
      josmPictureCommand("/zoom", selectedPicture);
      setLastZoomedPicture(selectedPicture);
    } else if (followInJosm && selectedPicture === null) {
      setLastZoomedPicture(null);
    }
  } catch (e) {
    console.log(e);
  }

  const handleReverse360 = () => {
    if (rotateOffset === 0) {
      setRotationReversed(true);
      setRotateOffset(180);
    } else {
      setRotationReversed(false);
      setRotateOffset(0);
    }
  };

  const handleExportModalOpen = () => {
    setModalPage(1);
    if (tripSelected) {
      setExportSelection(null);
      setExportView(null);
      toggleExportModalOpen();
    }
  };

  const handleSetModalPage = (e) => {
    if (e === 2 && exportSelection === "Sequence") {
      setModalPage(3);
      return;
    }
    if (e === 4) {
      handleExportGPX();
    }
    if (e === 5) {
      handleExportModalOpen();
    }
    setModalPage(e);
  };

  const handleExportGPX = () => {
    exportGPX(exportSelection, exportView, sequenceSelected);
  };

  const handleSetExportSelection = (e) => {
    if (e.target.value === "Sequence") {
      if (!sequenceSelected) {
        alert("You must first select a sequence to use this method");
        return;
      }
    }
    setExportSelection(e.target.value);
  };

  const handleSetExportView = (e) => {
    setExportView(e.target.value);
  };

  const handleClearGPX = () => {
    setGPXfile(null);
    if (mapRef && mapRef.current) {
      if (mapRef.current.getMap().getSource("gpx_file_data")) {
        mapRef.current.getMap().removeLayer("file_data_layer");
        mapRef.current.getMap().removeSource("gpx_file_data");
      }
    }
  };

  const handleSetFilterParameters = (value) => {
    const isValuePresent = filterParameters.includes(value);
    if (isValuePresent) {
      const updatedParameters = filterParameters.filter(
        (param) => param !== value,
      );
      setFilterParameters(updatedParameters);
    } else {
      const updatedParameters = [...filterParameters, value];
      setFilterParameters(updatedParameters);
    }
  };
  const handleToggleSearchOpen = () => {
    toggleFilterMenuOpen(false);
    toggleToolMenuOpen(false);
    toggleLayerMenuOpen(false);
    toggleSearchOpen(true);
  };

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/login");
  };

  const imageViewer = selectedPicture ? (
    <ImageViewer />
  ) : (
    <ImagePlaceholder isMapSwap={isMapSwap} />
  );

  return (
    <>
      <SearchModal searchOpen={searchOpen} />
      <FilterModal
        handleToggleFilterMenu={handleToggleFilterMenu}
        filterMenuOpen={filterMenuOpen}
        handleSetFilterParameters={handleSetFilterParameters}
      />
      <ToolsModal
        rotationReversed={rotationReversed}
        toolMenuOpen={toolMenuOpen}
        handleSetImageBrightness={handleSetImageBrightness}
        handleFollowInJosm={handleFollowInJosm}
        handleReverse360={handleReverse360}
        handleClearGPX={handleClearGPX}
        handleExportModalOpen={handleExportModalOpen}
        handleFlyTo={handleFlyTo}
        onClickLoadAndZoom={onClickLoadAndZoom}
      />
      <ExportGpxModal
        exportModalOpen={exportModalOpen}
        handleExportModalOpen={handleExportModalOpen}
        modalPage={modalPage}
        handleSetModalPage={handleSetModalPage}
        exportSelection={exportSelection}
        handleSetExportSelection={handleSetExportSelection}
        exportView={exportView}
        handleSetExportView={handleSetExportView}
        selectedView={selectedView}
        handleExportGPX={handleExportGPX}
        page_1_text={"GPX contents"}
        page_2_text={"View Direction"}
        title_text={"Export GPX"}
        sub
      />
      {!user ? (
        <div
          style={{
            position: "absolute",
            zIndex: "9999",
            margin: "1vh",
            left: "0vw",
          }}
        >
          <StyledButton
            button_text={"Login"}
            button_action={handleButtonClick}
          />
        </div>
      ) : (
        <></>
      )}

      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "1vw",
          width: "98%",
          alignItems: "flex-end",
          zIndex: "9999",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            gap: "2vw",
          }}
        >
          <Minimap
            intiallyDisplayMinimap={publicMap}
            user={user}
            isMapSwap={isMapSwap}
            imageViewer={imageViewer}
            handleIsMapSwap={handleIsMapSwap}
          />
          <ToolBar />
        </div>

        <LayerWidget
          style={{
            left: "1vw",
          }}
        />
      </div>

      {isMapSwap ? imageViewer : <DropzoneMap />}

      <div
        style={{
          position: "absolute",
          right: "1vw",
          top: "1vh",
        }}
      >
        <SearchWidget handleToggleSearchOpen={handleToggleSearchOpen} />
        <FilterWidget
          handleToggleFilterMenu={handleToggleFilterMenu}
          filterMenuOpen={filterMenuOpen}
          handleSetFilterParameters={handleSetFilterParameters}
        />

        <ToolsWidget handleToggleToolsMenu={handleToggleToolsMenu} />
      </div>
    </>
  );
};
