import React, { useContext } from "react";
import { DataContext } from "../../Contexts/DataContext";
import { circleStyle, spinnerStyle } from "./styles";

export const LoadingSpinner = () => {
  const { isLoading } = useContext(DataContext);
  return (
    <>
      {isLoading ? (
        <div style={spinnerStyle}>
          <div style={circleStyle}></div>
          <h4 style={{ color: "orange" }}>Loading...</h4>
        </div>
      ) : null}
    </>
  );
};
