import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../Contexts/DataContext";
import info_icon from "../../images/info_icon.png";
import filter_icon from "../../images/filter_icon.png";
import tools_icon from "../../images/tools_icon.png";
import polygon_icon from "../../images/polygon_icon.png";
import addImageIcon from "../../images/addImageIcon.png";
// import dark_layer_icon from "../../images/dark_layer_icon.png";
import street_layer_icon from "../../images/street_layer_icon.png";
// import sat_layer_icon from "../../images/sat_layer_icon.png";
// import outdoor_layer_icon from "../../images/outdoor_layer_icon.png";
import sat_streets_layer_icon from "../../images/sat_streets_layer_icon.png";
import search_icon from "../../images/search_icon.png";
import { imageryType, MAPBOX_TOKEN } from "../../components/constants";
import swapIcon from "../../images/swapIcon.png";
import minimizeIcon from "../../images/minimizeIcon.png";
import { Tooltip } from "react-tooltip";
import expandIcon from "../../images/expandIcon.png";
import { MiniMapWrapper } from "./styles";
import downChevron from "../../images/downchevron.png";
import upChevron from "../../images/upchevron.png";
import { DropzoneMap } from "../../components/features/map/Dropzone";
import useToggle from "../../hooks/useToggle";
import { DirectionPicker } from "../../components/features/map/CameraDirection";
import { HotBar } from "../../components/features/map/HotBar";

import { SearchBox } from "@mapbox/search-js-react";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";

export const ProjectIcon = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.74);
  border-radius: 6px;
  margin-bottom: 2px;
  margin-left: 2px;
  padding: 3px;
  z-index: 9999;
`;

export const SearchModal = (props) => {
  const { mapRef } = useContext(DataContext);

  const handleSearch = (res) => {
    const feature = res.features[0];
    const lat = feature.geometry.coordinates[0];
    const lng = feature.geometry.coordinates[1];
    mapRef.current.getMap().easeTo({
      center: [lat, lng],
      zoom: 12,
      duration: 300,
    });
  };
  const handleSearchError = (error) => {
    console.log(error);
  };

  const checkForCords = (value) => {
    // Regular expression to match latitude and longitude coordinates
    const latLngRegex =
      /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

    // Check if the value matches the regular expression
    if (latLngRegex.test(value)) {
      // Extract latitude and longitude values from the match
      const [lat, lng] = value.split(",");
      // Update the map with the extracted coordinates
      try {
        mapRef.current.getMap().easeTo({
          center: [lng, lat], // Corrected latitude value
          zoom: 12,
          duration: 300,
        });
      } catch (e) {
        alert(e);
      }
      return "";
    } else {
      return value;
    }
  };

  return (
    <>
      {props.searchOpen ? (
        <>
          <div
            style={{
              zIndex: "999",
              position: "absolute",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              top: "1vh",
              right: "50px",
              boxShadow: "1px 1px 6px 2px gray",
              borderRadius: "6px",
              padding: "5px",
              width: "400px",
              maxHeight: "40vh",
            }}
          >
            <SearchBox
              accessToken={MAPBOX_TOKEN}
              options={{
                language: "en",
              }}
              onRetrieve={handleSearch}
              onSuggestError={handleSearchError}
              mapboxgl={mapboxgl}
              interceptSearch={checkForCords}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export const FilterModal = (props) => {
  const {
    setFilterStartDate,
    setFilterEndDate,
    unfilteredViews,
    setUnfilteredViews,
    filterStartDate,
    filterEndDate,
  } = useContext(DataContext);

  const handleStartDateChange = (event) => {
    const selectedDate = event.target.value;
    setFilterStartDate(selectedDate);
  };

  const handleEndDateChange = (event) => {
    const selectedDate = event.target.value;
    setFilterEndDate(selectedDate);
  };

  function onCheckboxChange(event) {
    const value = event.target.id;
    const isChecked = event.target.checked;
    const updatedViews = isChecked
      ? [...unfilteredViews, value]
      : unfilteredViews.filter((view) => view !== value);
    setUnfilteredViews(updatedViews);
  }

  return (
    <>
      {props.filterMenuOpen ? (
        <>
          <div
            style={{
              zIndex: "999",
              position: "absolute",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              top: "1vh",
              right: "5vw",
              boxShadow: "1px 1px 6px 2px gray",
              borderRadius: "6px",
              padding: "5px",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <strong>Filter Imagery</strong>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <label htmlFor="start_date">Start Date:</label>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  onChange={handleStartDateChange} // Call function on change
                  value={filterStartDate}
                />
                <label htmlFor="end_date">End Date:</label>
                <input
                  type="date"
                  id="end_date"
                  name="end_date"
                  onChange={handleEndDateChange} // Call function on change
                  value={filterEndDate}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor="Front">Front</label>
                  <input
                    type={"checkbox"}
                    id="Front"
                    name="Front"
                    onChange={onCheckboxChange}
                    checked={unfilteredViews.includes("Front")}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor="Left">Left</label>
                  <input
                    type={"checkbox"}
                    id="Left"
                    name="Left"
                    onChange={onCheckboxChange}
                    checked={unfilteredViews.includes("Left")}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor="Right">Right</label>
                  <input
                    type={"checkbox"}
                    id="Right"
                    name="Right"
                    onChange={onCheckboxChange}
                    checked={unfilteredViews.includes("Right")}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor="360">360</label>
                  <input
                    type={"checkbox"}
                    id="360"
                    name="360"
                    onChange={onCheckboxChange}
                    defaultChecked={unfilteredViews.includes("360")}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export const FilterWidget = (props) => {
  return (
    <>
      <ProjectIcon
        className="filter-icon"
        src={filter_icon}
        onClick={() => props.handleToggleFilterMenu()}
      />
      <Tooltip
        style={{ zIndex: 9999 }}
        anchorSelect=".filter-icon"
        place="left"
      >
        Filter
      </Tooltip>
    </>
  );
};

export const LayerWidget = () => {
  const { currentImagery, handle_change_imagery } = useContext(DataContext);

  const imageryOptions = [
    {
      type: imageryType.SATSTREETS,
      label: "Sat-Streets",
      imageSrc: sat_streets_layer_icon,
    },
    {
      type: imageryType.STREETS,
      label: "Street",
      imageSrc: street_layer_icon,
    },
  ];

  return (
    <>
      {imageryOptions.map((option) => {
        if (currentImagery === option.type) {
          return null;
        }

        return (
          <div key={option.type}>
            <div
              style={{
                pointerEvents: "auto",
              }}
            >
              <ProjectIcon
                className="layer-icon"
                src={option.imageSrc}
                alt={option.label}
                style={{
                  width: "5vw",
                  height: "5vw",
                }}
                onClick={() => {
                  handle_change_imagery(option.type);
                }}
              />
            </div>
          </div>
        );
      })}
      <Tooltip
        style={{ zIndex: 9999 }}
        anchorSelect=".layer-icon"
        place="bottom"
      >
        Layers
      </Tooltip>
    </>
  );
};

export const Minimap = (props) => {
  const [minimapOpen, toggleMiniMapOpen] = useToggle(
    !props.intiallyDisplayMinimap,
  );
  const [minimapTop, toggleMinimapTop] = useToggle(true);

  return minimapOpen === true ? (
    <>
      <div
        style={{
          width: "35vw",
        }}
      >
        <MiniMapWrapper
          style={{
            bottom: minimapTop ? "1vw" : "72vh",
            pointerEvents: "auto",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              marginTop: "1vh",
            }}
          >
            <MinimizeIcon handleSetMiniMapState={toggleMiniMapOpen} />
            <SwapWidget handleIsMapSwap={props.handleIsMapSwap} />
            <MoveToolIcon
              toggleToolTop={toggleMinimapTop}
              toolTop={minimapTop}
            />
          </div>
          {props.isMapSwap ? <DropzoneMap /> : props.imageViewer}
        </MiniMapWrapper>
      </div>
    </>
  ) : (
    <>
      <ExpandIcon handleSetMiniMapState={toggleMiniMapOpen} />
      <MiniMapWrapper style={{ opacity: 0, width: "30.75vw" }}>
        {props.isMapSwap ? <DropzoneMap /> : props.imageViewer}
      </MiniMapWrapper>
    </>
  );
};

export const MoveToolIcon = (props) => {
  return (
    <>
      <ProjectIcon
        className="map-swap-icon"
        src={props.toolTop ? upChevron : downChevron}
        alt="expand minimap"
        onClick={() => props.toggleToolTop()}
      />
    </>
  );
};

export const ExpandIcon = (props) => {
  return (
    <div
      style={{
        position: "relative",
        pointerEvents: "auto",
      }}
    >
      <ProjectIcon
        className="expand-icon"
        src={expandIcon}
        alt="expand minimap"
        onClick={() => props.handleSetMiniMapState()}
      />
      <Tooltip
        style={{ zIndex: 9999 }}
        anchorSelect=".expand-icon"
        place="bottom"
      >
        Expand Mini Map
      </Tooltip>
    </div>
  );
};

export const MinimizeIcon = (props) => {
  return (
    <>
      <ProjectIcon
        className="minimize-icon"
        src={minimizeIcon}
        onClick={() => props.handleSetMiniMapState()}
      />
      <Tooltip
        style={{ zIndex: 9999 }}
        anchorSelect=".minimize-icon"
        place="bottom"
      >
        Minimize
      </Tooltip>
    </>
  );
};

export const ToolBar = () => {
  const { handleSetAutoplayTimer, autoplayTimer, isPlaying } =
    useContext(DataContext);
  const [toolbarTop, toggleToolbarTop] = useToggle(true);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: toolbarTop ? "column-reverse" : "column",
          position: "relative",
          alignItems: "center",
          pointerEvents: "auto",
          bottom: toolbarTop ? "1vw" : "82vh",
          gap: "1vh",
        }}
      >
        <div>
          <DirectionPicker />
          <HotBar />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {isPlaying ? (
            <input
              className=".playback-speed-slider"
              style={{
                width: "6vw",
                height: "2vh",
                position: "relative",
                zIndex: "9999",
              }}
              type="range"
              min={500}
              max={4000}
              step={100}
              value={autoplayTimer}
              onChange={(e) => handleSetAutoplayTimer(e)}
            />
          ) : (
            <span
              style={{
                width: "6vw",
              }}
            ></span>
          )}
          <MoveToolIcon toggleToolTop={toggleToolbarTop} toolTop={toolbarTop} />
          <span
            style={{
              width: "6vw",
            }}
          ></span>
        </div>
      </div>
    </>
  );
};

export const SwapWidget = (props) => {
  return (
    <>
      <ProjectIcon
        className="swap-icon"
        src={swapIcon}
        onClick={() => props.handleIsMapSwap()}
      />
      <Tooltip
        style={{ zIndex: 9999 }}
        anchorSelect=".swap-icon"
        place="bottom"
      >
        Swap Map
      </Tooltip>
    </>
  );
};

export const ToolsWidget = (props) => {
  return (
    <>
      <ProjectIcon
        className="tools-icon"
        src={tools_icon}
        onClick={() => props.handleToggleToolsMenu()}
      />
      <Tooltip
        style={{ zIndex: 9999 }}
        anchorSelect=".tools-icon"
        place="left"
        effect="solid"
      >
        Tools
      </Tooltip>
    </>
  );
};

export const ToolsModal = (props) => {
  const { imageBrightness, followInJosm, selectedPicture } =
    useContext(DataContext);
  return (
    <>
      {props.toolMenuOpen ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "1px 1px 6px 2px gray",
              zIndex: "999",
              borderRadius: "6px",
              top: "1vh",
              right: "3.35vw",
              padding: "1vw",
              rowGap: "1vh",
              minWidth: "22vw",
              gap: "1vh",
              position: "absolute",
              backgroundColor: "ghostwhite",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1vh",
                  minWidth: "15vw",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <strong>Tools</strong>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {<strong>Image Brightness: {`${imageBrightness}%`}</strong>}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="range"
                    min={0}
                    max={200}
                    value={imageBrightness}
                    step={0.1}
                    onChange={(e) => props.handleSetImageBrightness(e)}
                  ></input>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {<strong>Follow in Josm</strong>}
                  <input
                    type="checkbox"
                    checked={followInJosm}
                    onChange={() => props.handleFollowInJosm()}
                    style={{ marginLeft: "1vw" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {<strong>Reverse 360's</strong>}
                  <input
                    type="checkbox"
                    checked={props.rotationReversed === true}
                    onChange={() => props.handleReverse360()}
                    style={{ marginLeft: "1vw" }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1vh",
                }}
              >
                <button
                  style={{ backgroundColor: "white" }}
                  onClick={() => props.handleClearGPX()}
                >
                  Clear GPX
                </button>

                <button
                  style={{ backgroundColor: "white" }}
                  onClick={() => props.handleExportModalOpen()}
                >
                  Export GPX
                </button>
                {/* <button
                  style={{ backgroundColor: "white" }}
                  onClick={() => props.handleFlyTo()}
                >
                  Go To Selected
                </button> */}
                <button
                  style={{ backgroundColor: "white" }}
                  onClick={(e) => props.onClickLoadAndZoom(e)}
                >
                  View in Josm
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export const PolygonWidget = (props) => {
  return (
    <>
      <ProjectIcon
        className="polygon-icon"
        src={polygon_icon}
        onClick={() => props.handleTogglePolygonOpen()}
      />
      <Tooltip
        style={{ zIndex: 9999 }}
        anchorSelect=".polygon-icon"
        place="left"
      >
        Draw
      </Tooltip>
    </>
  );
};

export const AddImagesWidget = (props) => {
  return (
    <>
      <ProjectIcon
        className="images-icon"
        src={addImageIcon}
        onClick={() => props.handleToggleAddImagesOpen()}
      />

      <Tooltip
        style={{ zIndex: 9999 }}
        anchorSelect=".images-icon"
        place="left"
      >
        Images
      </Tooltip>
    </>
  );
};

export const AddImagesModals = (props) => {
  const { handleSetTempImages } = useContext(DataContext);
  return (
    <>
      {props.addImagesOpen === true ? (
        <>
          <div
            style={{
              position: "absolute",
              top: "1vh",
              right: "3vw",
              width: "20vw",
              height: "23vh",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: "1px 1px 6px 2px gray",
              zIndex: "999",
              overflowY: "scroll",
              borderRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: ".5vh",
                marginBottom: ".5vh",
              }}
            >
              <strong>View Images</strong>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "auto",
                width: "90%",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "6px",
                paddingLeft: "1vh",
                paddingRight: "1vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "5vh",
                  zIndex: "999",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <strong>Drive Sequence:</strong>
                <input
                  type="radio"
                  value="drive"
                  checked={props.tempImageryType === "drive"}
                  onChange={(e) => props.handleSetImageryType(e)}
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                  }}
                />

                <strong>P.O.I:</strong>
                <input
                  type="radio"
                  value="poi"
                  onChange={(e) => props.handleSetImageryType(e)}
                  checked={props.tempImageryType === "poi"}
                  style={{
                    marginLeft: "1vw",
                  }}
                />
              </div>
            </div>
            {props.tempImageryType && props.tempImageryType === "drive" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    width: "90%",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    paddingLeft: "1vh",
                    paddingRight: "1vh",
                    marginTop: "1vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      height: "5vh",
                      zIndex: "999",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: "1vh",
                      paddingRight: "1vh",
                    }}
                  >
                    <strong>Front</strong>
                    <input
                      type="radio"
                      value="Front"
                      checked={props.cameraFacing === "Front"}
                      onChange={(e) => props.handleSetCameraFacing(e)}
                      style={{
                        marginLeft: ".5vw",
                        marginRight: ".5vw",
                      }}
                    />
                    <strong>Left</strong>
                    <input
                      type="radio"
                      value="Left"
                      checked={props.cameraFacing === "Left"}
                      onChange={(e) => props.handleSetCameraFacing(e)}
                      style={{
                        marginLeft: ".5vw",
                        marginRight: ".5vw",
                      }}
                    />

                    <strong>Right</strong>
                    <input
                      type="radio"
                      value="Right"
                      checked={props.cameraFacing === "Right"}
                      onChange={(e) => props.handleSetCameraFacing(e)}
                      style={{
                        marginLeft: ".5vw",
                        marginRight: ".5vw",
                      }}
                    />

                    <strong>360</strong>
                    <input
                      type="radio"
                      value="360"
                      checked={props.cameraFacing === "360"}
                      onChange={(e) => props.handleSetCameraFacing(e)}
                      style={{
                        marginLeft: ".5vw",
                        marginRight: ".5vw",
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
            {props.tempImageryType &&
            props.tempImageryType === "drive" &&
            props.cameraFacing ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    width: "90%",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    paddingLeft: "1vh",
                    paddingRight: "1vh",
                    marginTop: "1vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      height: "5vh",
                      // backgroundColor:'lightblue',
                      zIndex: "999",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <strong>Select Images:</strong>
                    <input
                      style={{ width: "auto" }}
                      type="file"
                      multiple
                      accept=".jpg, .JPG, .jpeg, .JPEG"
                      onChange={(e) =>
                        handleSetTempImages(
                          e,
                          props.tempImageryType,
                          props.cameraFacing,
                        )
                      }
                    />
                  </div>
                </div>
              </>
            ) : props.tempImageryType && props.tempImageryType === "poi" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    width: "90%",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    paddingLeft: "1vh",
                    paddingRight: "1vh",
                    marginTop: "1vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      height: "5vh",
                      // backgroundColor:'lightblue',
                      zIndex: "999",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <strong>Select Images:</strong>
                    <input
                      type="file"
                      multiple
                      accept=".jpg, .JPG, .jpeg, .JPEG"
                      onChange={(e) =>
                        handleSetTempImages(
                          e,
                          props.tempImageryType,
                          props.cameraFacing,
                        )
                      }
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
};

export const SearchWidget = (props) => {
  return (
    <>
      <ProjectIcon
        className="location-icon"
        src={search_icon}
        onClick={() => props.handleToggleSearchOpen()}
      />
      <Tooltip
        style={{ zIndex: 9999 }}
        anchorSelect=".location-icon"
        place="left"
      >
        Search
      </Tooltip>
    </>
  );
};

export const PlaybackSlider = (props) => {
  const { toolbarSwapped } = props;
  return (
    <input
      className=".playback-speed-slider"
      style={{
        width: "4vw",
        height: "2vh",
        position: "absolute",
        left: ".5vw",
        bottom: toolbarSwapped ? "-5.5vh" : "9vh",
      }}
      type="range"
      min={0.1}
      max={5.0}
      step={0.1}
      onChange={(e) => props.handleSetAutoplayTimer(e)}
    />
  );
};
