// IMPORTS
import React, { useContext, useEffect, useState } from "react";
import { DataProvider } from "./Contexts/DataContext";
import { InteractionProvider } from "./Contexts/InteractionContext";
import { AuthContext, AuthProvider } from "./Contexts/AuthContext";
import { Viewer } from "./pages/Viewer";
import { Login } from "./pages/Login";
import { PageNotFound } from "./pages/PageNotFound";
import { AdminUsersPage } from "./pages/AdminUsersPage";
import { AdminUploadPage } from "./pages/AdminUploadPage";
import { AdminTripPage } from "./pages/AdminTripsPage";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { RegisterUser } from "./pages/RegisterUser";
import { Root } from "./root";

// APP DECLARATION
function App() {
  const Private = ({ Component }) => {
    const { user } = useContext(AuthContext);
    const auth = user && user;
    if (auth) {
      if (
        (user.role && user.role === "admin") ||
        (user.role && user.role === "user")
      ) {
        return <Component />;
      } else {
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  };

  function ErrorBoundary({ children }) {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
      const handleError = (error, errorInfo) => {
        // Log the error to the console or send it to your server
        console.error(error, errorInfo);
        setHasError(true);
      };

      window.addEventListener("error", handleError);

      return () => {
        window.removeEventListener("error", handleError);
      };
    }, []);

    if (hasError) {
      // You can render a fallback UI or nothing at all
      return null;
    }

    return children;
  }

  return (
    <>
      {/* COMPONENT RENDER - APP PAGE ROUTER */}
      <ErrorBoundary>
        <Router>
          <InteractionProvider>
            <DataProvider>
              <AuthProvider>
                <Routes>
                  <Route exact={true} path="/" element={<Viewer />} />
                  <Route exact={true} path="/login" element={<Login />} />
                  <Route
                    exact={true}
                    path="/registerUser"
                    element={<RegisterUser />}
                  />
                  <Route element={PageNotFound} />
                  <Route path="/AdminTripPage" element={<Root />}>
                    <Route
                      index
                      element={<Private Component={AdminTripPage} />}
                    />
                  </Route>
                  <Route path="/AdminUsersPage" element={<Root />}>
                    <Route
                      index
                      element={<Private Component={AdminUsersPage} />}
                    />
                  </Route>
                  <Route path="/AdminUploadPage" element={<Root />}>
                    <Route
                      index
                      element={<Private Component={AdminUploadPage} />}
                    />
                  </Route>
                  <Route path="/viewer" element={<Root />}>
                    <Route index element={<Viewer />} />
                  </Route>
                </Routes>
              </AuthProvider>
            </DataProvider>
          </InteractionProvider>
        </Router>
      </ErrorBoundary>
    </>
  );
}

export default App;
