// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active a {
  background: rgba(244, 117, 60, 0.1);
  border-right: solid 5px #f4753c;
  transition: all 0.5s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/features/sidebar/styles.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,+BAA+B;EAC/B,yBAAyB;AAC3B","sourcesContent":[".active a {\n  background: rgba(244, 117, 60, 0.1);\n  border-right: solid 5px #f4753c;\n  transition: all 0.5s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
