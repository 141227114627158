import { CancelConfirmButtons } from "../base/cancelConfirmButtons";
import { SectionSubtitle } from "../base/sectionSubtitle";
import { ModalTitle, ModalWrapper } from "../base/modalComponents";
import { Modal } from "@mui/material";

export const GenericModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  confirmText,
  onConfirm,
  showBack,
  onBack,
  children,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <ModalTitle title={title} closeButtonAction={onClose} />
        {subtitle && <SectionSubtitle subtitle_text={subtitle} />}
        {children}
        <CancelConfirmButtons
          confirm_text={confirmText}
          confirm_action={onConfirm}
          cancel_text={showBack ? "Back" : "Cancel"}
          cancel_action={showBack ? onBack : onClose}
        />
      </ModalWrapper>
    </Modal>
  );
};
