import { TableCell, Typography } from "@mui/material";
import React from "react";

export const TableEntryCell = (props) => {
  return (
    <TableCell
      style={{ height: "2vh" }}
      align="center"
      component="th"
      scope="row"
      onClick={
        props.click_action && props.click_action
          ? () => props.click_action()
          : null
      }
    >
      <Typography variant="subtitle2" noWrap style={{ textAlign: "left" }}>
        {props.entry}
      </Typography>
    </TableCell>
  );
};
