import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import { DataContext } from "../../Contexts/DataContext";
import "./styles.css";
import { AddTripModal } from "../../components/features/tripModals/addTripModal";
import { TopDiv } from "../../components/base/styles";
import { ModifyTripModal } from "../../components/features/tripModals/modifyTripModal";
import useToggle from "../../hooks/useToggle";
import { DeleteTripModal } from "../../components/features/tripModals/deleteTripModal";
import {
  deleteTrip,
  fetchTripByType,
  setTripToArchive,
  setTripToComplete,
  setTripToUploading,
} from "../../services/tripService";
import CustomTable from "../../components/composite/table/customTable";
import { ButtonDivComponent } from "../../components/composite/buttonDivComponent";
import { ConfirmModalCommon } from "../../components/composite/confirmModal";

export const AdminTripPage = () => {
  const {
    tripSelected,
    handleSetTripSelected,
    handleFetchOrgTrips,
    trips,
    setTrips,
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);

  const [openAddTripModal, setOpenAddTripModal] = useState(false);
  const [openModifyTripModal, toggleModifyTripModal] = useToggle(false);
  const [tripStatus, setTripStatus] = useState("");
  const [tripName, setTripName] = useState("");
  const [tripCity, setTripCity] = useState("");
  const [tripCountry, setTripCountry] = useState("");
  const [tripDescription, setTripDescription] = useState("");
  const [confirmOpen, toggleConfirmOpen] = useToggle(false);
  const [statusAction, setStatusAction] = useState(null);
  const [deleteOpen, toggleDeleteOpen] = useToggle(false);
  const [tripType, setTripType] = useState("ready");

  useEffect(() => {
    if (user === null || user.role !== "admin") {
      window.location.href = "/login";
    }
  }, []);

  const handleTripTypeChange = (newTripType) => {
    setTripType(newTripType);
  };
  const handleOpenModal = () => {
    setOpenAddTripModal(true);
  };

  const handleModifyOpen = () => {
    if (tripSelected !== null) {
      //If the modal is already open reset states before closing
      if (openModifyTripModal) {
        setTripName("");
        setTripCity("");
        setTripCountry("");
        setTripDescription("");
        handleSetTripSelected(null);
      }
      toggleModifyTripModal();
    } else {
      alert("No Trip Selected");
    }
  };

  const handleDeleteOpen = () => {
    if (tripSelected !== null) {
      toggleDeleteOpen();
    } else {
      alert("No Trip Selected");
    }
  };

  const handleConfirmOpen = () => {
    toggleConfirmOpen();
  };

  const handleSetStatusAction = (e) => {
    setStatusAction(e);
  };

  const archiveTrip = async (trip_id) => {
    const response = await setTripToArchive(trip_id);
    if (response.status === 200) {
      handleFetchOrgTrips("new");
      handleFetchOrgTrips("ready");
      handleFetchOrgTrips("archived");
    } else {
      console.error(response);
    }
  };

  const completeTrip = async (trip_id) => {
    const response = await setTripToComplete(trip_id);
    if (response.status === 200) {
      handleFetchOrgTrips("new");
      handleFetchOrgTrips("ready");
      handleFetchOrgTrips("archived");
    } else {
      console.error(response);
    }
  };

  const handleDeleteTrip = async (trip_id) => {
    try {
      const response = await deleteTrip(trip_id);
      console.log(response);
      if (response.status === 200) {
        handleFetchOrgTrips("new");
        handleFetchOrgTrips("ready");
        handleFetchOrgTrips("archived");
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error("Error deleting trip:", error);
    } finally {
      handleSetTripSelected(null);
      handleDeleteOpen();
    }
  };

  const handleUploadingTrip = async () => {
    const response = await setTripToUploading(tripSelected);
    if (response.status === 200) {
      handleFetchOrgTrips("new");
      handleFetchOrgTrips("ready");
      handleFetchOrgTrips("archived");
    } else {
      console.error(response);
    }
    toggleConfirmOpen(false);
    toggleModifyTripModal();
  };
  const handleCompleteTrip = () => {
    completeTrip(tripSelected);
    toggleConfirmOpen(false);
    toggleModifyTripModal();
  };
  const handleArchiveTrip = () => {
    archiveTrip(tripSelected);
    toggleConfirmOpen(false);
    toggleModifyTripModal();
  };

  const handleClickSelectTrip = (rowData) => {
    if (rowData["id"] !== tripSelected) {
      setTripName(rowData["name"]);
      setTripCity(rowData["city"]);
      setTripCountry(rowData["country"]);
      handleSetTripSelected(rowData["id"]);
      setTripDescription(rowData["desc"]);
      setTripStatus(rowData["status"]);
    } else {
      if (tripSelected !== null) {
        setTripName(null);
        handleSetTripSelected(null);
        setTripStatus(null);
      }
    }
  };

  useEffect(() => {
    async function fetchTripsByType() {
      const response = await fetchTripByType(tripType);
      if (response.status === 200) {
        setTrips(response.data);
      }
    }
    fetchTripsByType();
  }, [tripType]);

  const headers = ["Name", "ID", "Country", "City", "Vehicles", "Date Added"];

  const column_keys = [
    "name",
    "id",
    "country",
    "city",
    "vehicles",
    "date_created",
  ];

  return (
    <>
      <AddTripModal
        addOpen={openAddTripModal}
        toggleAddOpen={setOpenAddTripModal}
      />

      <ModifyTripModal
        role={user.role}
        modifyOpen={openModifyTripModal}
        toggleModifyOpen={toggleModifyTripModal}
        tripName={tripName}
        tripCity={tripCity}
        tripCountry={tripCountry}
        tripDescription={tripDescription}
        tripId={tripSelected}
        tripStatus={tripStatus}
        tripSelected={tripSelected}
        handleModifyOpen={handleModifyOpen}
        archive_button={false}
        completed_button={true}
        handleConfirmOpen={handleConfirmOpen}
        handleSetStatusAction={handleSetStatusAction}
        archiveTrip={archiveTrip}
        completeTrip={completeTrip}
        uploadingTrip={handleUploadingTrip}
      />

      <DeleteTripModal
        deleteOpen={deleteOpen}
        tripName={tripName}
        selected_projects={tripSelected}
        handle_delete_trip={() => handleDeleteTrip(tripSelected)}
        handleDeleteOpen={handleDeleteOpen}
      />

      <ConfirmModalCommon
        modal_open={confirmOpen}
        interrogative={`Are you sure you want to move Project: ${tripName} to the ${statusAction} Tab?`}
        button_1_text={"Cancel"}
        button_2_text={"Confirm"}
        button_1_action={handleConfirmOpen}
        button_2_action={
          statusAction === "Uploading"
            ? () => handleUploadingTrip(tripSelected)
            : statusAction === "Ready"
              ? () => handleCompleteTrip(tripSelected)
              : statusAction === "Archived"
                ? () => handleArchiveTrip(tripSelected)
                : null
        }
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {user.id === 25 || user.id === 232 ? (
          <button
            style={{
              boxShadow: "1px 1px 6px 2px gray",
              textAlign: "center",
              lineHeight: "2.0em",
              height: "4vh",
              backgroundColor: "#203124",
              color: "white",
            }}
          >
            Clear Bookmarks
          </button>
        ) : null}
      </div>

      <TopDiv>
        <ButtonDivComponent
          buttons={[
            { action: () => handleTripTypeChange("ready"), text: "Ready" },
            {
              action: () => handleTripTypeChange("new"),
              text: "New/Uploading",
            },
            {
              action: () => handleTripTypeChange("archived"),
              text: "Archived",
            },
          ]}
        />

        <ButtonDivComponent
          buttons={[
            { action: handleOpenModal, text: "Add" },
            {
              action: handleModifyOpen,
              text: "Edit",
            },
            // {
            //   action: handleDeleteOpen,
            //   text: "Delete",
            // },
          ]}
        />
      </TopDiv>
      <CustomTable
        headers={headers}
        data={trips}
        columns={column_keys}
        handleOnClick={handleClickSelectTrip}
      />
    </>
  );
};
