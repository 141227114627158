import { Card, TableRow } from "@mui/material";
import styled from "styled-components";

export const TableCard = styled(Card)(() => ({
  width: "100%",
  marginLeft: "0vw",
  boxShadow: "0 0 4px gray",
  overflowY: "scroll",
}));

export const TableCell = styled.div`
  font-size: 1rem;
  position: relative;
  width: 25vw;
  margin-top: 1rem;
  white-space: wrap;
`;

export const TableTitle = styled.header`
  font-size: 1rem;
  font-weight: bold;
`;

export const ButtonDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  textAlign: "center",
  justifyContent: "center",
}));

export const TableEntryRow = styled(TableRow)(() => ({
  width: "100vw",
  height: "1vh",
  marginLeft: "2vw",
  paddingTop: "1vh",
  paddingBottom: "1vh",
}));

export const TopDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "2vh",
}));

export const CardMediaStyle = styled("div")(() => ({
  display: "flex",
  position: "relative",
  justifyContent: "center",
  paddingTop: "3vh",
  "&:before": {
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
    // backdropFilter: "blur(3px)",
    // WebkitBackdropFilter: "blur(3px)", // Fix on Mobile
    backgroundColor: "#f4753c",
    fontWeight: "400",
  },
}));

export const spinnerStyle = {
  position: "fixed",
  left: "45%",
  top: "45%",
  width: "15vw",
  height: "15vh",
  margin: "auto",
  padding: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  zIndex: 9999,
  backgroundColor: "black",
};

export const circleStyle = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  border: "4px solid #f3f3f3",
  borderTop: "4px solid #3498db",
  animation: `spin ${3}s linear infinite`,
  marginBottom: "10px",
  zIndex: 999,
};

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const Button = styled.div`
  color: white;
  text-transform: capitalize;
  background-color: #253e45;
  width: 8rem;
  margin-right: 1vw;
  height: 4vh;
  border-radius: 6px;
  padding: 1px 1px;
  &:hover {
    cursor: pointer;
    background-color: #91a5ac;
  }
`;

export const ButtonLabel = styled.div`
  margin: 2px;
`;

export const Input = styled.input`
  box-sizing: inherit;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
  width: 100%;
  align-self: center;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 6px;
`;

export const TextArea = styled.textarea`
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
  width: 90%;
  display: flex;
  align-self: center;
  padding: 12px 20px;
  height: 75%;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
`;
export const ProjectIcon = styled.img`
  width: 35px;
  height: 35px;
`;

export const SelectWrapper = styled.div`
  margin-left: 2em;
  margin-right: 2em;
`;
export const MapIcon = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.74);
  border-radius: 6px;
  margin-bottom: 2px;
  margin-left: 2px;
  padding: 3px;
  z-index: 9999;
`;
