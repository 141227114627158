import { Table } from "@mui/material";
import { DataContext } from "../../../common/DataContext";
import { AuthContext } from "../../../common/AuthContext";
import React, { useContext, useEffect } from "react";
import { ARCHIVE_TABLE_HEAD } from "./tripComponents";

import {
  CardMediaStyle,
  ListHead,
  TableCard,
  TableEntryRow,
  TableEntryCell,
} from "../../commonComponents/commonComponents";

export const ArchivedTripTab = (props) => {
  // DATA CONTEXT STATES AND FUNCTIONS //
  const {
    handleFetchOrgTrips,
    tripSelected,
    archivedTrips,
    handleSetTripSelected,
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);

  // FETCH ALL ORG TRIPS ON COMPONENT MOUNT //
  useEffect(() => {
    handleSetTripSelected(null);
    if (user && user.role === "admin") {
      handleFetchOrgTrips("archived");
    }
  }, []);

  // COMPONENT RENDER //
  return (
    <>
      <div style={{ height: "80vh", width: "100%" }}>
        <TableCard
          style={{
            boxShadow: "1px 1px 6px 2px gray",
            height: "85%",
            width: "99%",
            overflowY: "scroll",
          }}
        >
          <CardMediaStyle />
          <Table>
            <ListHead
              headLabel={ARCHIVE_TABLE_HEAD}
              // tableData={newTrips}
              // updateData={setNewTrips}
            />
            {archivedTrips &&
              archivedTrips.slice().map((row) => {
                const {
                  name,
                  id,
                  status,
                  description,
                  date_created,
                  country,
                  city,
                  total_days,
                  total_teams,
                  vehicles,
                  day_uploading,
                } = row;
                return (
                  <TableEntryRow
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(145, 165, 172, 0.5)",
                        cursor: "pointer",
                      },
                    }}
                    align="center"
                    // hover
                    key={id}
                    tabIndex={-1}
                    onClick={() =>
                      props.handleClickSelectTrip(
                        id,
                        name,
                        description,
                        city,
                        country,
                        status,
                      )
                    }
                    selected={tripSelected === id}
                    onDoubleClick={() => props.view_all_project_sequences(id)}
                  >
                    <TableEntryCell entry={<strong>{name}</strong>} />
                    <TableEntryCell entry={id} />
                    <TableEntryCell entry={country} />
                    <TableEntryCell entry={city} />
                    <TableEntryCell entry={vehicles} />
                    <TableEntryCell entry={date_created} />
                  </TableEntryRow>
                );
              })}
          </Table>
        </TableCard>
      </div>
    </>
  );
};
