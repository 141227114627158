import { SidebarIcon } from "../sidebar/styles";
import day_icon from "../../../images/day_icon.png";
import React from "react";
import camera_icon from "../../../images/cameraIcon.png";
import vehicle_icon from "../../../images/carIcon.png";
import { SectionSubtitle } from "../../base/sectionSubtitle";

export const TravelDayUIcard = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2vh",
            height: "15vh",
            width: "60%",
            boxShadow: "1px 1px 6px 2px gray",
            marginBottom: "2vh",
          }}
        >
          <SidebarIcon
            src={day_icon}
            style={{
              margin: "auto",
              marginLeft: "1vw",
              height: "3vh",
              width: "auto",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "15vh",
              width: "80%",
              alignItems: "left",
              justifyContent: "left",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginLeft: "0vw",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Day of Travel:</strong>}
              />
              <SectionSubtitle
                align={"left"}
                subtitle_text={props.day_of_travel}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Date:</strong>}
              />
              <SectionSubtitle align={"left"} subtitle_text={props.date} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Facings:</strong>}
              />
              {props.cameras &&
                props.cameras.slice().map((camera) => {
                  const { facing } = camera;
                  return (
                    <SectionSubtitle align={"left"} subtitle_text={facing} />
                  );
                })}
            </div>
          </div>
          <input
            type="radio"
            value={props.id}
            style={{ marginRight: "3vw" }}
            onChange={() =>
              props.handleSetSelectedDay(
                props.id,
                props.day_of_travel,
                props.cloud_path,
              )
            }
            checked={props.selectedDay === props.id}
          />
        </div>
      </div>
    </>
  );
};

export const CameraUIcard = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2vh",
            height: "15vh",
            width: "60%",
            boxShadow: "1px 1px 6px 2px gray",
            marginBottom: "2vh",
          }}
        >
          <SidebarIcon
            src={camera_icon}
            style={{
              margin: "auto",
              marginLeft: "1vw",
              height: "3vh",
              width: "auto",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "15vh",
              width: "80%",
              alignItems: "left",
              justifyContent: "left",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginLeft: "0vw",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Camera Facing:</strong>}
              />
              <SectionSubtitle align={"left"} subtitle_text={props.facing} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Make:</strong>}
              />
              <SectionSubtitle
                align={"left"}
                subtitle_text={props.type ? props.type : "N/A"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Model:</strong>}
              />
              <SectionSubtitle
                align={"left"}
                subtitle_text={props.model ? props.model : "N/A"}
              />
            </div>
          </div>
          <input
            type="radio"
            value={props.id}
            style={{ marginRight: "3vw" }}
            onChange={() =>
              props.handleSetSelectedCamera(props.id, props.facing)
            }
            checked={props.selectedCamera === props.id}
          />
        </div>
      </div>
    </>
  );
};

export const VehicleUIcard = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2vh",
            height: "15vh",
            width: "60%",
            boxShadow: "1px 1px 6px 2px gray",
            marginBottom: "2vh",
          }}
        >
          <SidebarIcon
            src={vehicle_icon}
            style={{
              margin: "auto",
              marginLeft: "1vw",
              height: "3vh",
              width: "auto",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "15vh",
              width: "80%",
              alignItems: "left",
              justifyContent: "left",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginLeft: "0vw",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Vehicle:</strong>}
              />
              <SectionSubtitle align={"left"} subtitle_text={props.name} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Days of travel:</strong>}
              />
              <SectionSubtitle
                align={"left"}
                subtitle_text={props.travel_days.length / props.cameras.length}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Cameras:</strong>}
              />
              {props.cameras &&
                props.cameras.slice().map((camera) => {
                  const { facing } = camera;
                  return (
                    <SectionSubtitle align={"left"} subtitle_text={facing} />
                  );
                })}
            </div>
          </div>
          <input
            type="radio"
            value={props.id}
            style={{ marginRight: "3vw" }}
            onChange={() =>
              props.handleSetSelectedVehicle(props.id, props.name)
            }
            checked={props.selectedVehicle === props.id}
          />
        </div>
      </div>
    </>
  );
};
