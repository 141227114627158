// GENERAL SECTION SUBTITLE
import { Typography } from "@mui/material";
import React from "react";

export const SectionSubtitle = (props) => {
  return (
    <Typography
      variant="body1"
      align={props.align ? props.align : "center"}
      style={{
        width: "auto",
        paddingLeft: props.marginLeft ? props.marginLeft : "1vw",
        paddingRight: "1vw",
        marginBottom: "1vh",
        marginRight: props.marginRight ? props.marginRight : "0vw",
        marginTop: "1vh",
        ...props.style,
      }}
      sx={{ mt: 6 }}
    >
      {props.subtitle_text}
    </Typography>
  );
};
