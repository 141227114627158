import React, { useContext, useEffect, useState } from "react";
import useToggle from "../../../hooks/useToggle.js";
import { DataContext } from "../../../common/DataContext";
import { AddDriveTeamModal } from "./tripComponents";
import { NewVehicleModal } from "./tripComponents";
import { v4 as uuidv4 } from "uuid";
import { ProjectIcon } from "./styles";
import vehicle_icon from "../../../images/carIcon.png";
import { Divider, Modal } from "@mui/material";

import {
  CancelButton,
  CloseButton,
  ConfirmButton,
  ModalWrapper,
  SectionSubtitle,
  SectionTitle,
} from "../../commonComponents/commonComponents";

import {
  TripNameField,
  TripCountryField,
  TripCityField,
  BackupFolderField,
  DriveTeamField,
  VehiclesField,
} from "./tripComponents";
// ADD PROJECT MODAL //
export const AddTripModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      if (props.addOpen) {
        props.handleAddOpen();
      }
    }
  };
  const [page, setPage] = useState(1);
  const [vehiclePage, setVehiclePage] = useState(1);

  const [datesArray, setDatesArray] = useState([]);
  const [daysTraveled, setDaysTraveled] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [vehicleComplete, toggleVehicleComplete] = useToggle(false);
  const [vehicleIndex, setVehicleIndex] = useState(0);
  const [confirmText, setConfirmText] = useState("Next");
  const [addUserModalOpen, toggleAddUserModalOpen] = useToggle(false);
  const [addVehicleModalOpen, toggleAddVehicleModalOpen] = useToggle(false);
  const [highlight, setHighlight] = useState(false);
  const [allCameras, toggleAllCameras] = useToggle(false);
  const [tempCameraArray, setTempCameraArray] = useState([]);
  const [visibility, toggleVisibility] = useToggle(false);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [tempOrgUsers, setTempOrgUsers] = useState([]);
  const [form, setForm] = useState({
    name: "",
    desc: "",
    country: "",
    city: "",
    days: "",
    total_vehicles: 0,
  });
  const [vehicles, setVehicles] = useState([]);
  const [driveTeamNames, setDriveTeamNames] = useState([]);
  const isCameraSelected = (e) => tempCameraArray.includes(e);

  const {
    tripStartDate,
    setTripStartDate,
    tripEndDate,
    setTripEndDate,
    backupFolder,
    setBackupFolder,
    handleCreateTrip,
    setDriveTeam,
    driveTeam,
    tripSelected,
  } = useContext(DataContext);

  const handleAddOpen = () => {
    setPage(1);
    props.toggleAddOpen();
    setDriveTeam([]);

    setForm({
      name: "",
      desc: "",
      country: "",
      city: "",
      days: "",
      total_vehicles: 0,
    });
  };

  useEffect(() => {
    handleClearStates();
    setPage(1);
    setVehiclePage(1);
    // eslint-disable-next-line
  }, [props.addOpen]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const days = [];
      let currentDate = new Date(startDate);
      let end = new Date(endDate);
      while (currentDate <= end) {
        days.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      setDatesArray(days);
      setDaysTraveled(days.length);
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    let check_array = ["Front", "Left", "Right", "360"];
    if (areAllElementsInArray(check_array, tempCameraArray)) {
      toggleAllCameras(true);
    } else {
      toggleAllCameras(false);
    }
    // eslint-disable-next-line
  }, [tempCameraArray]);

  useEffect(() => {
    if (vehicleIndex === vehicles.length && vehicleComplete) {
      setPage(5);
      setConfirmText("Create");
    }
    toggleVehicleComplete(false);
    // eslint-disable-next-line
  }, [vehicleIndex, vehicleComplete]);

  const handleToggleAddUserModalOpen = () => {
    toggleAddUserModalOpen();
  };

  const handleToggleAddVehicleModalOpen = () => {
    toggleAddVehicleModalOpen();
  };

  const handleSetVisibility = (e) => {
    toggleVisibility();
  };

  const handleSetName = (e) => {
    setName(e.target.value);
  };
  const handleSetDesc = (e) => {
    setDescription(e.target.value);
  };

  const handleSetCity = (e) => {
    setCity(e.target.value);
  };

  const handleSetCountry = (e) => {
    setCountry(e.target.value);
  };

  const handleSetStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleSetEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleSetCameras = (facing) => {
    if (facing === "all") {
      if (allCameras) {
        toggleAllCameras(false);
        setTempCameraArray([]);
      } else {
        toggleAllCameras(true);
        setTempCameraArray(["Front", "Left", "Right", "360"]);
      }
    } else {
      if (!tempCameraArray.includes(facing)) {
        setTempCameraArray([...tempCameraArray, facing]);
      } else {
        setTempCameraArray(tempCameraArray.filter((i) => i !== facing));
      }
    }
  };

  const handleSetVehicleDates = () => {
    if (startDate !== null && endDate !== null) {
      vehicles[vehicleIndex].startDate = startDate;
      vehicles[vehicleIndex].endDate = endDate;
      vehicles[vehicleIndex].daysTraveled = daysTraveled;
      vehicles[vehicleIndex].cameras = tempCameraArray;
    }
    toggleVehicleComplete(true);
    setDatesArray([]);
    setTempCameraArray([]);
    setDaysTraveled(0);
    setVehicleIndex((index) => index + 1);
    setVehiclePage(1);
  };

  function areAllElementsInArray(testArray, stateArray) {
    for (let i = 0; i < testArray.length; i++) {
      if (!stateArray.includes(testArray[i])) {
        return false;
      }
    }
    return true;
  }

  const handleSetVehiclePage = () => {
    if (vehiclePage === 1) {
      setVehiclePage(2);
      return;
    }
    if (vehiclePage === 2) {
      handleSetVehicleDates();
      return;
    }
  };

  const handleSetPage = (direction) => {
    if (page === 1) {
      if (direction === "back") {
        handleAddOpen();
      } else {
        if (name && country && tripStartDate && tripEndDate) {
          setHighlight(false);
          setPage(2);
        } else {
          setHighlight(true);
        }
      }
      return;
    }
    if (page === 2) {
      if (direction === "back") {
        setPage(1);
      } else {
        setPage(3);
      }
      return;
    }
    if (page === 3) {
      if (vehicles.length < 1 && direction === "forward") {
        setConfirmText("Create");
        setPage(5);
        return;
      }
      if (direction === "back") {
        setPage(2);
        return;
      } else {
        setPage(4);
        return;
      }
    }

    if (page === 4) {
      handleSetVehiclePage();
      if (direction === "back") {
        setPage(3);
        return;
      }
    }
    if (page === 5) {
      if (direction === "back") {
        setConfirmText("Next");
        setVehicleIndex(0);
        setVehiclePage(1);
        setPage(3);
      }
      if (confirmText === "Create" && direction === "forward") {
        submitCreateTrip();
      }
    }
  };

  const submitCreateTrip = () => {
    handleCreateTrip(
      visibility,
      name,
      description,
      country,
      city,
      form.total_vehicles,
      vehicles,
    );
    setVehicleIndex(0);
    setVehicles([]);

    //Admin upload page specific props
    if (typeof props.handleTripSelected === "function") {
      props.handleTripSelected(tripSelected, name);
      props.toggleDestinationSelected(true);
      console.log(typeof props.toggleDestinationSelected);
    }

    handleAddOpen();
  };

  const handleClearStates = () => {
    setName(null);
    setDescription(null);
    setCity(null);
    setCountry(null);
    setVehicles([]);
    setDriveTeam([]);
    setPage(1);
    setConfirmText("Next");
    setHighlight(false);
  };

  const handleSetVehicles = (name, make, model) => {
    for (let i = 0; i < vehicles.length; i++) {
      if (Object.values(vehicles[i]).includes(name)) {
        alert(
          "Vehicle name already used for this trip, please choose a different name",
        );
        return;
      }
    }
    let vehicle_obj = {
      name: name,
      make: make,
      model: model,
      cameras: [],
      startDate: null,
      endDate: null,
      daysTraveled: 0,
    };
    setVehicles([...vehicles, vehicle_obj]);
    handleToggleAddVehicleModalOpen();
  };

  const handleSetTripStartDate = (e) => {
    const startDate = e.target.value;
    if (tripEndDate) {
      if (startDate > tripEndDate) {
        alert("Start date must come before end date");
        return;
      }
    }

    setTripStartDate(startDate);
  };

  const handleSetTripEndDate = (e) => {
    const endDate = e.target.value;
    if (tripStartDate) {
      if (endDate < tripStartDate) {
        alert("End date must come after start date");
        return;
      }
    }
    setTripEndDate(endDate);
  };

  const handleSetBackupFolder = (e) => {
    setBackupFolder(e.target.value);
  };

  function formatDate(datetime) {
    if (datetime != null) {
      const date = new Date(datetime);
      const timezoneOffset = date.getTimezoneOffset();
      const adjustedDate = new Date(
        date.getTime() - timezoneOffset * 60 * 1000,
      );
      const month = adjustedDate.getMonth() + 1; // Months are 0-indexed
      const day = adjustedDate.getDate();
      const year = adjustedDate.getFullYear() % 100; // Extract last two digits of the year
      return `${month}/${day}/${year}`;
    } else {
      return "";
    }
  }

  return (
    <>
      <AddDriveTeamModal
        open={addUserModalOpen}
        close_action={toggleAddUserModalOpen}
        confirm_action={handleToggleAddUserModalOpen}
        trip_id={tripSelected}
        tempOrgUsers={tempOrgUsers}
        driveTeam={driveTeam}
        handleSetDriveTeam={setDriveTeam}
      />

      <NewVehicleModal
        title={"Edit Vehicle"}
        modalState={addVehicleModalOpen}
        modalHandler={handleToggleAddVehicleModalOpen}
        handleSetVehicles={handleSetVehicles}
      />

      <Modal open={props.addOpen} key="add" onKeyDown={handleKeyDown}>
        <ModalWrapper>
          <CloseButton close_action={handleAddOpen} />

          {page === 1 ? (
            <>
              <SectionTitle title_text={<strong>Create New Trip</strong>} />
              <SectionSubtitle
                subtitle_text={
                  <strong>The Trip will be added to your Organization</strong>
                }
              />
              <Divider />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SectionSubtitle
                  subtitle_text={<strong>Visibility (Required)</strong>}
                />
                <SectionSubtitle subtitle_text={<strong>Public:</strong>} />
                <input
                  value={"Public"}
                  checked={visibility === true}
                  onChange={(e) => handleSetVisibility(e)}
                  type="radio"
                  required
                />
                <SectionSubtitle subtitle_text={<strong>Private:</strong>} />
                <input
                  value={"Private"}
                  checked={visibility === false}
                  onChange={(e) => handleSetVisibility(e)}
                  type="radio"
                  required
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  overflowY: "scroll",
                }}
              >
                <SectionSubtitle
                  subtitle_text={<strong>Trip Name (Required)</strong>}
                />

                <TripNameField
                  name={name}
                  handleSetName={handleSetName}
                  isMissing={highlight}
                />

                <SectionSubtitle
                  subtitle_text={<strong>Date Range (required)</strong>}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "6vh",
                    width: "90%",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor:'lightgrey'
                  }}
                >
                  <SectionSubtitle
                    subtitle_text={<strong>Start Date:</strong>}
                  />
                  <input
                    value={tripStartDate}
                    style={{
                      width: "13vw",
                      borderColor: highlight ? "red" : undefined,
                      borderWidth: highlight ? "2px" : undefined,
                      borderStyle: highlight ? "solid" : undefined,
                    }}
                    type="date"
                    onChange={(e) => handleSetTripStartDate(e)}
                  />
                  <span style={{ width: "2vw" }} />
                  <SectionSubtitle subtitle_text={<strong>End Date:</strong>} />
                  <input
                    value={tripEndDate}
                    style={{
                      width: "13vw",
                      borderColor: highlight ? "red" : undefined,
                      borderWidth: highlight ? "2px" : undefined,
                      borderStyle: highlight ? "solid" : undefined,
                    }}
                    type="date"
                    onChange={(e) => handleSetTripEndDate(e)}
                  />
                </div>

                <SectionSubtitle
                  subtitle_text={<strong>Country (required)</strong>}
                />
                <TripCountryField
                  isMissing={highlight}
                  country={country}
                  handleSetCountry={handleSetCountry}
                />
                <SectionSubtitle
                  subtitle_text={<strong>City (optional)</strong>}
                />
                <TripCityField city={city} handleSetCity={handleSetCity} />

                <SectionSubtitle
                  subtitle_text={<strong>Backup Folder (optional)</strong>}
                />
                <BackupFolderField
                  backupFolder={backupFolder}
                  handleSetBackupFolder={handleSetBackupFolder}
                />
              </div>
            </>
          ) : page === 2 ? (
            <>
              <SectionTitle
                title_text={<strong>Add Drive Team (optional)</strong>}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                  width: "100%",
                  paddingRight: "1.5vw",
                  marginTop: "2vh",
                  marginBottom: "2vh",
                }}
              >
                <div style={{ width: "62%" }}>
                  <SectionSubtitle
                    subtitle_text={<strong>Drive Team:</strong>}
                  />
                </div>
                <ConfirmButton
                  confirm_text={"edit"}
                  confirm_action={handleToggleAddUserModalOpen}
                />
              </div>
              <DriveTeamField
                driveTeam={driveTeam}
                handleSetDriveTeam={setDriveTeam}
              />
            </>
          ) : page === 3 ? (
            <>
              <SectionTitle
                title_text={<strong>Add Vehicles (optional)</strong>}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                  width: "100%",
                  paddingRight: "1.5vw",
                  marginTop: "2vh",
                  marginBottom: "2vh",
                }}
              >
                <div style={{ width: "62%" }}>
                  <SectionSubtitle subtitle_text={<strong>Vehicles:</strong>} />
                </div>
                <ConfirmButton
                  confirm_text={"Add"}
                  confirm_action={handleToggleAddVehicleModalOpen}
                />
              </div>

              <VehiclesField vehicles={vehicles} />
            </>
          ) : page === 4 ? (
            <>
              {vehiclePage && vehiclePage === 1 ? (
                <>
                  {vehicles &&
                  vehicles.length > 0 &&
                  vehicleIndex < vehicles.length ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          height: "auto",
                        }}
                      >
                        <SectionTitle
                          title_text={
                            <strong>{`Add Cameras to ${vehicles[vehicleIndex].name} (optional)`}</strong>
                          }
                        />

                        <SectionSubtitle
                          subtitle_text={<strong>Facing:</strong>}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "3vw",
                            marginTop: "0vw",
                            marginBottom: "2vh",
                            height: "4vh",
                            width: "45vw",
                            alignItems: "top",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              height: "4vh",
                              marginRight: "2vw",
                            }}
                          >
                            <SectionTitle
                              title_text={"Front"}
                              marginTop={"1vh"}
                            />
                            <input
                              type="checkbox"
                              value="front"
                              checked={isCameraSelected("Front")}
                              onChange={(e) => handleSetCameras("Front")}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              height: "4vh",
                              marginRight: "2vw",
                            }}
                          >
                            <SectionTitle
                              title_text={"Left"}
                              marginTop={"1vh"}
                            />
                            <input
                              type="checkbox"
                              value="left"
                              checked={isCameraSelected("Left")}
                              onChange={(e) => handleSetCameras("Left")}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              height: "4vh",
                              marginRight: "2vw",
                            }}
                          >
                            <SectionTitle
                              title_text={"Right"}
                              marginTop={"1vh"}
                            />
                            <input
                              type="checkbox"
                              value="right"
                              checked={isCameraSelected("Right")}
                              onChange={(e) => handleSetCameras("Right")}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              height: "4vh",
                              marginRight: "2vw",
                            }}
                          >
                            <SectionTitle
                              title_text={"360"}
                              marginTop={"1vh"}
                            />
                            <input
                              type="checkbox"
                              value="360"
                              checked={isCameraSelected("360")}
                              onChange={(e) => handleSetCameras("360")}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              height: "4vh",
                              marginRight: "2vw",
                            }}
                          >
                            <SectionTitle
                              title_text={"All"}
                              marginTop={"1vh"}
                            />
                            <input
                              type="checkbox"
                              value="All"
                              checked={allCameras}
                              onChange={(e) => handleSetCameras("all")}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : vehiclePage === 2 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SectionTitle
                      title_text={
                        <strong>{`Add days of travel for ${vehicles[vehicleIndex].name} (optional)`}</strong>
                      }
                    />
                    <div style={{ display: "flex", marginBottom: "1vh" }}>
                      <SectionSubtitle
                        subtitle_text={<strong>{"Start Date"}</strong>}
                      />
                      <input
                        type="date"
                        onChange={(e) => handleSetStartDate(e)}
                      />
                    </div>
                    <div style={{ display: "flex", marginBottom: "1vh" }}>
                      <SectionSubtitle
                        subtitle_text={<strong>{"End Date"}</strong>}
                      />
                      <input
                        type="date"
                        onChange={(e) => handleSetEndDate(e)}
                      />
                    </div>
                    <SectionSubtitle
                      style={{ marginBottom: "1vh" }}
                      subtitle_text={
                        <strong>{`Total Days of Travel: ${daysTraveled}`}</strong>
                      }
                    />
                  </div>
                </>
              ) : null}
            </>
          ) : page === 5 ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "auto",
                  width: "100%",
                  overflowY: "scroll",
                }}
              >
                <SectionTitle
                  title_text={<strong>Review Details and Create Trip</strong>}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "left",
                    marginLeft: "2vw",
                  }}
                >
                  <SectionSubtitle
                    subtitle_text={<strong>Trip Name:</strong>}
                  />
                  <SectionSubtitle subtitle_text={name} />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "left",
                    marginLeft: "2vw",
                  }}
                >
                  <SectionSubtitle subtitle_text={<strong>Country:</strong>} />
                  <SectionSubtitle subtitle_text={country} />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "left",
                    marginLeft: "2vw",
                  }}
                >
                  <SectionSubtitle subtitle_text={<strong>City:</strong>} />
                  <SectionSubtitle subtitle_text={city} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "left",
                    marginLeft: "2vw",
                  }}
                >
                  <SectionSubtitle
                    subtitle_text={<strong>Description:</strong>}
                  />
                  <SectionSubtitle subtitle_text={description} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "left",
                      marginLeft: "1vw",
                    }}
                  >
                    <SectionSubtitle
                      subtitle_text={<strong>Drive Team:</strong>}
                    />
                    {driveTeam &&
                      driveTeam.slice().map((row) => {
                        const { name } = row;
                        return (
                          <>
                            <SectionSubtitle subtitle_text={name} />
                          </>
                        );
                      })}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "auto",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "left",
                      marginLeft: "2vw",
                    }}
                  >
                    <SectionSubtitle
                      subtitle_text={<strong>Vehicles:</strong>}
                    />
                  </div>
                  {vehicles &&
                    vehicles.slice().map((row) => {
                      const {
                        id,
                        name,
                        daysTraveled,
                        startDate,
                        endDate,
                        cameras,
                        make,
                        model,
                      } = row;
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "2vh",
                              height: "15vh",
                              width: "90%",
                              boxShadow: "1px 1px 6px 2px gray",
                              marginBottom: "2vh",
                            }}
                            key={uuidv4()}
                          >
                            <ProjectIcon
                              src={vehicle_icon}
                              key={uuidv4()}
                              style={{
                                margin: "auto",
                                marginLeft: "1vw",
                                height: "3vh",
                                width: "auto",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "20vh",
                                width: "90%",
                                alignItems: "left",
                                justifyContent: "left",
                                margin: "auto",
                              }}
                              key={uuidv4()}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "left",
                                  marginLeft: "0vw",
                                }}
                                key={uuidv4()}
                              >
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={<strong>Vehicle:</strong>}
                                  key={uuidv4()}
                                />
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={name}
                                  key={uuidv4()}
                                />
                                <span style={{ width: "2vw" }} />
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={<strong>Make:</strong>}
                                  key={uuidv4()}
                                />
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={make}
                                  key={uuidv4()}
                                />

                                <span style={{ width: "2vw" }} />
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={<strong>Model:</strong>}
                                  key={uuidv4()}
                                />
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={model}
                                  key={uuidv4()}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "left",
                                }}
                                key={uuidv4()}
                              >
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={<strong>Total Days:</strong>}
                                  key={uuidv4()}
                                />
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={daysTraveled}
                                  key={uuidv4()}
                                />
                                <span style={{ width: "2vw" }} />
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={<strong>Dates:</strong>}
                                  key={uuidv4()}
                                />
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={`${formatDate(
                                    startDate,
                                  )}-${formatDate(endDate)}`}
                                  key={uuidv4()}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "left",
                                }}
                                key={uuidv4()}
                              >
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={<strong>Cameras:</strong>}
                                  key={uuidv4()}
                                />
                                {cameras &&
                                  cameras.slice().map((camera) => {
                                    return (
                                      <SectionSubtitle
                                        align={"left"}
                                        subtitle_text={camera}
                                        key={uuidv4()}
                                      />
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </>
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              justifyContent: "center",
              marginBottom: "1vh",
            }}
          >
            <CancelButton
              cancel_action={() => handleSetPage("back")}
              cancel_text={
                page === 1 ? "Cancel" : page !== 10 ? "Back" : "Cancel"
              }
            />
            <ConfirmButton
              confirm_text={confirmText}
              confirm_action={() => handleSetPage("forward")}
            />
          </div>
        </ModalWrapper>
      </Modal>
    </>
  );
};
