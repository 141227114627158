import useToggle from "../../hooks/useToggle";
import React, { useState } from "react";

export const DropdownMenu = ({
  options,
  onSelect,
  menu_title,
  processSubfolders = false,
  selectedFolder,
}) => {
  const [isOpen, setIsOpen] = useToggle(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleMenu = () => {
    setIsOpen();
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div>
      <button
        style={{
          padding: "8px 10px",
          border: "1px solid #ccc",
          borderRadius: "10px",
          outline: "none",
          boxShadow: "1px 2px 5px gray",
          width: "16vw",
        }}
        onClick={toggleMenu}
      >
        {selectedOption ? selectedOption.label : menu_title}
      </button>
      {isOpen && (
        <div
          style={{
            backgroundColor: "ghostwhite",
            height: "16vh",
            overflowY: "scroll",
          }}
        >
          {processSubfolders === false ? (
            <>
              <ul>
                {options.map((option) => (
                  <>
                    {typeof option === "string" ? (
                      <>
                        <li
                          key={option}
                          onClick={() => handleOptionSelect(option)}
                        >
                          {option}
                        </li>
                      </>
                    ) : (
                      <>
                        <li
                          key={option.value}
                          onClick={() => handleOptionSelect(option)}
                        >
                          {option.label}
                        </li>
                      </>
                    )}
                  </>
                ))}
              </ul>
            </>
          ) : (
            <>{selectedFolder}</>
          )}
        </div>
      )}
    </div>
  );
};
