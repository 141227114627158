import { poster } from "../calls";
export async function fetchTripByType(type) {
  try {
    return await poster({ upload_complete: type }, "project/fetch_org_trips");
  } catch (error) {
    console.error(error);
    return { status: 500, error: error };
  }
}

export async function createTrip(
  name,
  country = null,
  city = null,
  vehicles = null,
  tripStartDate,
  tripEndDate,
  bucket,
  endpoint,
  token,
  secret,
  region,
) {
  try {
    let outpack = {
      name: name,
      country: country,
      city: city,
      vehicles: vehicles,
      startDate: tripStartDate,
      endDate: tripEndDate,
      s3_bucket: bucket,
      s3_endpoint: endpoint,
      s3_token: token,
      s3_secret: secret,
      s3_region: region,
    };
    return poster(outpack, "project/create_trip");
  } catch (error) {
    console.error(error);
    return { status: 500, error: error };
  }
}

export async function setTripToUploading(trip_id) {
  try {
    return await poster(
      {
        trip_id: trip_id,
      },
      "project/uploading_trip",
    );
  } catch (error) {
    console.error(error);
    return { status: 500, error: error };
  }
}

export async function setTripToComplete(trip_id) {
  try {
    return await poster(
      {
        trip_id: trip_id,
      },
      "project/complete_trip",
    );
  } catch (error) {
    console.error(error);
    return { status: 500, error: error };
  }
}

export async function setTripToArchive(trip_id) {
  try {
    return await poster(
      {
        trip_id: trip_id,
      },
      "project/archive_trip",
    );
  } catch (error) {
    console.error(error);
    return { status: 500, error: error };
  }
}

export async function deleteTrip(trip_id) {
  try {
    return await poster(
      {
        trip_id: trip_id,
      },
      "project/delete_trip",
    );
  } catch (error) {
    console.error(error);
    return { status: 500, error: error };
  }
}

export async function editTrip(trip_id, trip_name, trip_city, trip_country) {
  try {
    return await poster(
      {
        id: trip_id,
        name: trip_name,
        city: trip_city,
        country: trip_country,
      },
      "project/modify_trip",
    );
  } catch (error) {
    console.error(error);
    return { status: 500, error: error };
  }
}
