import { Box, Container, Typography } from "@mui/material";
import NotFound from "../../images/404.jpg";

export const PageNotFound = () => {
  return (
    <>
      <div>
        <title>404 | Kaart SSO</title>
      </div>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            <strong>Error:</strong>
          </Typography>
          <Typography align="center" color="textPrimary" variant="h3">
            The page you are looking for isn’t here
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
        </Container>
      </Box>
    </>
  );
};
