export const MAPBOX_TOKEN =
  "pk.eyJ1Ijoia2FhcnQiLCJhIjoiY20wbXdpNHBmMDc5YzJscHp5OWc5Nmg2dSJ9._-_qiltBRipYKB5WLVSLkA";

export const imageryType = {
  SAT: "mapbox://styles/mapbox/satellite-v8",
  LIGHT: "mapbox://styles/mapbox/light-v8",
  DARK: "mapbox://styles/mapbox/dark-v8",
  STREETS: "mapbox://styles/mapbox/streets-v12",
  SATSTREETS: "mapbox://styles/mapbox/satellite-streets-v12",
  OUTDOORS: "mapbox://styles/mapbox/outdoors-v11",
};
Object.freeze(imageryType); // this line makes the Enum immutable

// // PROD;
export const API_URL = "https://viewer.kaart.com/api/";
export const SSO_URL = "https://my.kaart.com/api/";

//DEV
// export const API_URL = "http://dev.localhost:5002/api/";
// export const SSO_URL = "http://dev.localhost:5001/api/";
