import { poster } from "../calls";

export async function deleteSequence(delete_originals, selectedSequence) {
  try {
    return await poster(
      {
        sequence_id: selectedSequence,
        delete_originals: delete_originals,
      },
      "sequences/delete_sequence",
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getSequencesByTripId(project_id, direction) {
  try {
    return await poster(
      {
        project_id,
        direction,
      },
      "sequences/fetch_project_direction_sequences",
    );
  } catch (error) {
    console.error(error);
  }
}
/** TODO: passes day id but doesn't need it**/
export async function getSequencesByCameraId(day_id, camera_id) {
  try {
    return await poster(
      {
        day_id: day_id,
        camera_id: camera_id,
      },
      "sequences/fetch_day_sequences",
    );
  } catch (error) {
    console.error(error);
  }
}
