import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useEffect, useState } from "react";
export const SortedTableHead = ({ headLabel, tableData, updateData }) => {
  const [sort, setSort] = useState({ column: "", order: "asc" });
  const [isSorting, setIsSorting] = useState(false);
  const handleSort = (column) => {
    setSort((prevSort) => {
      let order;
      if (prevSort.column === column) {
        order = prevSort.order === "asc" ? "desc" : "asc";
      } else {
        order = "asc";
      }
      return { column, order };
    });
  };

  useEffect(() => {
    if (isSorting) {
      const sortData = () => {
        if (sort.column === "") {
          return;
        }
        const newData = [...tableData].sort((a, b) => {
          const valueA = a[sort.column];
          const valueB = b[sort.column];

          if (typeof valueA === "number" && typeof valueB === "number") {
            return sort.order === "asc" ? valueA - valueB : valueB - valueA;
          } else {
            const stringA = String(valueA).toUpperCase();
            const stringB = String(valueB).toUpperCase();
            if (stringA < stringB) return sort.order === "asc" ? -1 : 1;
            if (stringA > stringB) return sort.order === "asc" ? 1 : -1;
            return 0;
          }
        });
        updateData(newData);
      };
      sortData();
      setIsSorting(false);
    }
  }, [isSorting, sort, tableData, updateData]);

  useEffect(() => {
    setIsSorting(true);
  }, [sort]);

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell key={headCell.id}>
            <TableSortLabel
              active={sort.column === headCell.id}
              direction={sort.column === headCell.id ? sort.order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
